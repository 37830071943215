export enum AppTypeDto { "FILESAPP" = "FILESAPP", "INVOICEAPP" = "INVOICEAPP", "MAINAPP" = "MAINAPP", "PORTALAPP" = "PORTALAPP", "SIGNANDPAYAPP" = "SIGNANDPAYAPP", "SITEAPP" = "SITEAPP"};
export enum ApplicationFeatureName { "BookkeepingSync" = "BookkeepingSync", "FarpaySandboxIntegration" = "FarpaySandboxIntegration", "PenneoDevIntegration" = "PenneoDevIntegration", "PenneoProdIntegration" = "PenneoProdIntegration", "TenantAutoElectricityConsent" = "TenantAutoElectricityConsent"};
export enum CurrencyCode { "DKK" = "DKK", "Unknown" = "Unknown"};
export enum VerifySignuptokenResult { "ALREADY_VERIFIED" = "ALREADY_VERIFIED", "INVALID_TOKEN" = "INVALID_TOKEN", "NO_USER" = "NO_USER", "VERIFIED" = "VERIFIED"};
export interface AppPropertiesDto {
    basename: string;
    csrf: string;
    devmode: boolean;
    mainbaseurl: string;
    now_ms: number;
    signandpaybaseurl: string;
    site_auth: boolean;
}
export interface BMPaymentInstructions {
    dkBsCustomerNumber: string;
    dkBsDebitorGroupNumber: string;
    dkBsPbs: string;
}
export interface CreatePasswordResult {
    validateTokenResult: VerifySignuptokenResult;
}
export interface GetDashboardFlaggedMessage {
    address: string;
    estateKey: string;
    personsCsv: string;
    subject: string;
    tenancyId: number;
    tenantId: number;
}
export interface GetDashboardFlaggedMessages {
    list: GetDashboardFlaggedMessage[];
    pagi: PaginationInfo;
}
export interface GetDashboardGadgetArrear {
    address: string;
    balance: string;
    currency: string;
    key: string;
    tenancyId: number;
    tenantId: number;
    tenantNamesCsv: string;
}
export interface GetDashboardGadgetArrears {
    list: GetDashboardGadgetArrear[];
    pagi: PaginationInfo;
}
export interface GetDashboardNewMessagesDto {
    list: TenantUnreadCount[];
    pagi: PaginationInfo;
}
export interface GetDashboardReminderDto {
    address: string | null;
    dateMS: string;
    description: string;
    estateKey: string | null;
    estateName: string | null;
    reminderId: number;
    tenancyId: number | null;
    tenantId: number | null;
    title: string;
}
export interface GetDashboardRemindersDto {
    list: GetDashboardReminderDto[];
    pagi: PaginationInfo;
}
export interface GetEmptyTenanciesForDashboardGadget {
    list: GetEmptyTenancyForDashboard[];
    pagi: PaginationInfo;
}
export interface GetEmptyTenancyForDashboard {
    address: string;
    estateKey: string;
    next_inmover: string | null;
    tenancyId: number;
}
export interface GetTenantForMoveinDashboardGadget {
    address: string;
    estateKey: string;
    firstpaymentdueday: string;
    id: number;
    landlordSigned: boolean;
    prepaid: boolean;
    startday: string;
    tenancyId: number;
    tenantId: number;
    tenantSigned: boolean;
}
export interface GetTenantsForMoveinDashboardGadget {
    list: GetTenantForMoveinDashboardGadget[];
    pagi: PaginationInfo;
}
export interface GetTerminatedTenantForDashboardGadget {
    address: string;
    estateKey: string;
    startday: string;
    tenancyId: number;
    tenantId: number;
    tenantNamesCsv: string;
    terminationday: string | null;
}
export interface GetTerminatedTenantsForDashboardGadget {
    list: GetTerminatedTenantForDashboardGadget[];
    pagi: PaginationInfo;
}
export interface InvoiceAppContextDto {
}
export interface InvoiceDataDto {
    amounts: InvoiceDataDtoAmounts;
    bankPaymentInstruction: string | null;
    bmPaymentInstructions: BMPaymentInstructions | null;
    company: InvoiceDataDtoCompany;
    customer: InvoiceDataDtoCustomer;
    lines: InvoiceDataDtoLine[];
    meta: InvoiceDataDtoMeta;
}
export interface InvoiceDataDtoAmounts {
    currency: CurrencyCode;
    subtotal: string;
    total: string;
    vatted: boolean;
    vattotal: string;
}
export interface InvoiceDataDtoCompany {
    address: string;
    businessid: string;
    name: string;
}
export interface InvoiceDataDtoCustomer {
    address: string;
    businessid: string;
    names: string[];
}
export interface InvoiceDataDtoLine {
    amount: string;
    text: string;
    vat_amount: string | null;
}
export interface InvoiceDataDtoMeta {
    day: string;
    description: string;
    due: string;
    filename: string;
    invoiceUid: string;
    invoicename: string;
    number: string;
    number_text: string;
    paymentprocedurenote: string;
    title: string;
}
export interface LoginInput {
    email: string;
    fp: string;
    password: string;
    site: string | null;
}
export interface MainAppContextDto {
}
export interface MainSecurityControllerCheckSmsCodeDto {
    code: string;
    fp: string;
}
export interface MainSecurityControllerCheckSmsCodeResultDto {
    ok: boolean;
}
export interface PaginationInfo {
    next: boolean;
    page: number;
    pageSize: number;
    prev: boolean;
    total: number;
}
export interface PortalApiSignResultTenant {
    id: number;
    ident: string;
}
export interface PortalApiSigninResult {
    error: string | null;
    ok: PortalApiSignResultTenant[] | null;
}
export interface PortalAppContextDto {
    auth: boolean;
    baseurl: string;
    mainurl: string;
}
export interface RestErrorResult {
    fielderrors: { [key: string]: string } | null;
    validation_errors: RestErrorResultValidationErrors;
}
export interface RestErrorResultValidationErrors {
    tuples: ValidationErrorMsgTuple[];
}
export interface SignAndPayAppContextDto {
    baseurl: string;
}
export interface SigninInput {
    email: string;
    password: string;
}
export interface SiteAppContextDto {
    sandbox: boolean;
    siteauth: boolean;
    sitedomain: string;
    ui_features: ApplicationFeatureName[];
}
export interface SiteNameAndSubdomain {
    name: string;
    subdomain: string;
}
export interface TenantUnreadCount {
    address: string;
    estateKey: string;
    personsCsv: string;
    tenancyId: number;
    tenantId: number;
    unreadCount: number;
}
export interface ValidateTokenInput {
    onlycheck: boolean;
    password: string;
    site: string | null;
    token: string;
    userId: number;
}
export interface ValidationErrorMsgTuple {
    field: string;
    msg: string;
}
