import axios, { AxiosInstance } from "axios";

import { HttpFacade } from "./HttpFacade";

export class HttpDownloadFileFacade implements HttpFacade {

  private axios: AxiosInstance;

  constructor(baseurl:string, csrftoken : string) {
    const apis = axios.create({
      baseURL: baseurl,
      timeout: 60000,
      headers: {
        "CSRFToken": csrftoken, 
      },
    });
    this.axios = apis;
  }

  async get<T>(url): Promise<T> {
    const a = await this.axios.get(url, {
      responseType: "blob", 
    });
    return a.data;
  }

  async post<T>(url: string, data :any) : Promise<T> {
    const a = await this.axios.post(url, data, {
      responseType: "blob", 
    });
    return a.data;
  }

  // throw HttpFacadeError
  async put<T>(url: string, data :any) : Promise<T> {
    throw Error("put not supported");
  }

  // throw HttpFacadeError
  async delete<T>(url: string) : Promise<T> {
    throw Error("delete not supported");
  }

}
