import React, { Suspense } from "react";
import {
  QueryClient,
  QueryClientProvider,
} from "react-query";

import { DesignContext } from "./components/siteapp/contexts";
import { InferredContext } from "./uicontextloader";

const SignAndPayRouter = React.lazy(() => import ("./components/signandpayapp/SignAndPayRouter"));
const SiteRoutesLoader = React.lazy(() => import("./pages/site/routes"));
const MainApp = React.lazy(() => import ("./components/mainapp/MainAppRouter"));
const PortalRouter = React.lazy(() => import("./components/portalapp/PortalRouter"));
const FilesRouter = React.lazy(() => import("./components/filesapp/FilesRouter"));

const ApplicationLoader = (p: { context: InferredContext }) => {
  const queryClient = new QueryClient();

  const s = location.hostname.split(".");
  let subdomain: string | null = null;
  if (s.length >= 3) {
    subdomain = s[0];
  } else {
    throw Error("must always have subdomain");
  }

  let c: JSX.Element;
  if (subdomain === "main") {
    c = <MainApp requesterror={p.context.requesterror} />;
  } else if (subdomain === "portal") {
    c = <PortalRouter />;
  } else if (subdomain === "signandpay") {
    c = <SignAndPayRouter />;
  } else if (subdomain === "files") {
    c = <FilesRouter />;
  } else {
    c = <SiteRoutesLoader subdomain={subdomain} />;
  }

  return (
    <DesignContext.Provider
      value={{
        v2design: true,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <Suspense
          fallback={(
            <>
              <div
                className="pageloader"
                style={{
                  "opacity": 1,
                }}
              />
              <div
                className="infraloader"
                style={{
                  opacity: 1,
                }}
              />
            </>
          )}
        >
          {c}
        </Suspense>
      </QueryClientProvider>
    </DesignContext.Provider>
  );
};

export default ApplicationLoader;
