import { atom } from "jotai";
import {
  addSeconds,
  isAfter,
} from "date-fns";
import { createContext } from "react";

import { apiHttpFacade } from "../../contexts";
import { SiteUserApi_getMe } from "../../compiled_and_copied_from_java/restapi_methods_v1";
import { SiteContextDto } from "../../compiled_and_copied_from_java/restapi_dtos_v1";

export const siteContext = atom<SiteContextDto>({
  sandbox: true,
  siteuser_auth: false,
  sitebaseurl: "",
  subdomain: "",
  ui_features: [],
});

export const userContext = atom(async () => await SiteUserApi_getMe(apiHttpFacade));

export type AuthstampContextType = {
  stamp: string,
  expired: boolean,
  expires: Date
};
export const authstampContext = atom<AuthstampContextType>({
  stamp: "",
  expired: true,
  expires: new Date(),
});
export const updateAuthstampContext = atom(null, (_get, set, update: {stamp: string, expires : Date}) => {
  const expired = isAfter(addSeconds(new Date(), 5), update.expires);
  set(authstampContext, {
    stamp: update.stamp,
    expired,
    expires: update.expires, 
  });
});
export const refreshAuthstampContext = atom(null, (get, set) => {
  const g = get(authstampContext);
  const expired = isAfter(addSeconds(new Date(), 5), g.expires);
  if (expired != g.expired) {
    set(authstampContext, {
      stamp: g.stamp,
      expired: expired,
      expires: g.expires, 
    });
  }
});

type DesignContextType = {
  v2design: boolean
};
export const DesignContext = createContext<DesignContextType>({
  v2design: false,
});
