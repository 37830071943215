import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
} from "axios";

import {
  notificationDanger,
  notificationWarning,
} from "../components/ui2.0/Notification";

export type HttpFacadeError = {
  response?: {
    data: any,
    status: number
  },
  fielderrors?: any
};

export interface HttpFacade {
  get<T>(url, config?: AxiosRequestConfig): Promise<T>,

  post<T>(url: string, data: any, config?: AxiosRequestConfig): Promise<T>,

  put<T>(url: string, data: any, config?: AxiosRequestConfig): Promise<T>,

  delete<T>(url: string, config?: AxiosRequestConfig): Promise<T>
}

export class HttpApiFacade implements HttpFacade {

  private axios: AxiosInstance;

  constructor(baseurl: string, csrftoken: string) {
    this.axios = axios.create({
      baseURL: baseurl,
      timeout: 60000,
      headers: {
        "CSRFToken": csrftoken,
      },
    });
  }

  // throw HttpFacadeError
  async get<T>(url, config?: AxiosRequestConfig): Promise<T> {
    try {
      const w = await this.axios.get(url, config);
      return w.data;
    } catch (err) {
      // @ts-ignore
      if (err.response?.status === 401 || err.response?.status === 403) {
        debugger;
      }
      throw HttpApiFacade.handleErr(err);
    }
  }

  // throw HttpFacadeError
  async post<T>(url: string, data: any, config?: AxiosRequestConfig): Promise<T> {
    try {
      const a = await this.axios.post(url, data, config);
      return a.data;
    } catch (err) {
      throw HttpApiFacade.handleErr(err);
    }

  }

  // throw HttpFacadeError
  async put<T>(url: string, data: any, config?: AxiosRequestConfig): Promise<T> {
    try {
      const a = await this.axios.put(url, data, config);
      return a.data;
    } catch (err) {
      throw HttpApiFacade.handleErr(err);
    }
  }

  // throw HttpFacadeError
  async delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    try {
      const a = await this.axios.delete(url, config);
      return a.data;
    } catch (err) {
      throw HttpApiFacade.handleErr(err);
    }
  }

  private static handleErr(err: any): HttpFacadeError {
    const e = err as AxiosError;
    if (err.message == "useApi_cancel") {
      return e;
    }
    if (e.code == "ERR_CANCELED") {
      return e;
    }
    if (e.config == null) {
      notificationDanger("System error");
      return e;
    }
    if (e.response == null) {
      notificationDanger("System error");
      return e;
    }
    if (e.response.status === 422) {
      const resp_data = e.response.data as any;
      const fielderrors = resp_data?.fielderrors ?? null;

      if ("actionsRequired" in resp_data) {
        (e as any).actionsRequired = resp_data.actionsRequired;
        return e;
      }

      if ("userInstructions" in resp_data) {
        notificationDanger(resp_data["userInstructions"]);
        return e;
      }

      if (fielderrors == null) {
        notificationDanger(e.response.data);
        return e;
      }
      (e as any).fielderrors = fielderrors;
      const tuples: Array<ValidationErrorMsgTuple> = ((resp_data?.validation_errors ?? {}).tuples ?? []);
      (e as any).validation_errors_tuples = tuples;
      notificationWarning("Der er en fejl i det indtastede. Se formularen.");
      return e;
    } else if (e.response.status === 400) {
      notificationDanger("Server could not understand input");
      return e;
    } else if (e.response.status === 403) {
      notificationDanger("No access");
      return e;
    } else if (e.response.status === 401) {
      notificationDanger("No access.");
      return e;
    }
    return e;
  }

}

export type ValidationErrorMsgTuple = {
  field: string,
  msg: string
};
