import React, {
  Context,
  createContext,
} from "react";
import axios, { AxiosInstance } from "axios";

import {
  AppPropertiesDto,
  AppTypeDto,
  InvoiceAppContextDto,
  PortalAppContextDto,
  SignAndPayAppContextDto,
} from "./compiled_and_copied_from_java/internal_api";
import { HttpApiFacade } from "./shared/HttpFacade";
import {
  InferredContext,
  parseServerData,
} from "./uicontextloader";
import { HttpDownloadFileFacade } from "./shared/HttpDownloadFileFacade";

export let axios_instance: AxiosInstance = axios.create({
  baseURL: window.location.origin,
  timeout: 60000,
  headers: {
    "CSRFToken": "",
  },
});

export let SignAndPayAppContext: Context<SignAndPayAppContextDto> | null = null;
export let AppPropertiesContext: Context<AppPropertiesDto> = React.createContext<AppPropertiesDto>({
  signandpaybaseurl: "",
  now_ms: 0,
  mainbaseurl: "",
  devmode: false,
  site_auth: false,
  basename: "",
  csrf: "",
});

export let PortalAppContext: Context<PortalAppContextDto> | null = null;
export let InvoiceAppContext: Context<InvoiceAppContextDto> | null = null;

export let httpcontext: Context<{ api: HttpApiFacade, download_from_api: HttpDownloadFileFacade }> = React.createContext({
  api: new HttpApiFacade(window.location.origin, ""),
  download_from_api: new HttpDownloadFileFacade(window.location.origin, ""),
});

export let apiHttpFacade = new HttpApiFacade(window.location.origin, "");
export let apiDownloadBytesFacade = new HttpDownloadFileFacade(window.location.origin, "");

export const createContexts = (): InferredContext => {

  const infc: InferredContext = parseServerData();

  if (infc.type === AppTypeDto.SITEAPP) {
    // loading is done in "siteapp" now
  } else if (infc.type === AppTypeDto.PORTALAPP) {
    //@ts-ignore
    PortalAppContext = React.createContext(infc.context);
  } else if (infc.type === AppTypeDto.SIGNANDPAYAPP) {
    //@ts-ignore
    SignAndPayAppContext = React.createContext(infc.context);
  } else if (infc.type === AppTypeDto.FILESAPP) {
  } else if (infc.type === AppTypeDto.INVOICEAPP) {
    //@ts-ignore
    InvoiceAppContext = React.createContext(infc.context);
  } else if (infc.type === AppTypeDto.MAINAPP) {
    // no context for pages (yet)
  } else {
    throw Error("App Type not supported");
  }

  AppPropertiesContext = createContext(infc.properties);

  apiHttpFacade = new HttpApiFacade(window.location.origin, infc.properties.csrf);
  apiDownloadBytesFacade = new HttpDownloadFileFacade(window.location.origin, infc.properties.csrf);

  httpcontext = React.createContext({
    api: apiHttpFacade,
    download_from_api: new HttpDownloadFileFacade(window.location.origin, infc.properties.csrf),
  });

  axios_instance = axios.create({
    baseURL: window.location.origin,
    timeout: 60000,
    headers: {
      "CSRFToken": infc.properties.csrf,
    },
  });

  return infc;

};
