import React from "react";
import * as bulmaToast from "bulma-toast";
import { ToastPosition } from "bulma-toast";

export const notification = message => {
  bulmaToast.toast({
    message: message,
    position: "bottom-right",
    duration: 5000,
    type: "is-success",
  });
};

export const notificationWarning = (message, position: ToastPosition = "bottom-right") => {
  bulmaToast.toast({
    message: message,
    position: position,
    duration: 5000,
    type: "is-warning",
  });
};

export const notificationInfo = (message, position: ToastPosition = "bottom-right") => {
  bulmaToast.toast({
    message: message,
    position: position,
    duration: 5000,
    type: "is-info",
  });
};

export const notificationDanger = message => {
  bulmaToast.toast({
    message: message,
    position: "bottom-right",
    duration: 7000,
    type: "is-danger",
  });
};
