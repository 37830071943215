import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

export const loadI18N = onLoad => {
  i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      react: {
        useSuspense: false,
      },
      fallbackLng: "da",
      // @ts-ignore
      lng: "da", // window.navigator.userLanguage || window.navigator.language || 'da',
      debug: true,
      ns: ["server_generatedbyjava"],
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    }).then(onLoad);
};

// change language: i18n.changeLanguage("en") -> result is promise
