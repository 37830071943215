import { HttpFacade } from "../shared/HttpFacade";

import * as dtos from "./restapi_dtos_v1";

export const AdminDevApi_devoverridedate = (http: HttpFacade, __input: dtos.DevoverridedateInput): Promise<void> => {
  return http.post("/api/rest/1/admin/dev/overridedate", __input);
};

export const AdminDevApi_getDevoverridedate = (http: HttpFacade): Promise<number> => {
  return http.get("/api/rest/1/admin/dev/overridedate");
};

export const BankDaysApi_getFirstPaymentDateAfter = (http: HttpFacade, date: string): Promise<string> => {
  return http.get("/api/rest/1/rent/first_rent_payment_date_after?"+(date==null?"":"date="+date+"")+"");
};

export const BillSettingsApi_getSettingsForCompanyOwner = (http: HttpFacade, companyOwnerId: number): Promise<dtos.BillSettingsDto> => {
  return http.get("/api/rest/1/bills/settings/companyowner/"+companyOwnerId+"");
};

export const BillSettingsApi_getSettingsForTenancy = (http: HttpFacade, tenancyId: number): Promise<dtos.BillSettingsTenancyDto> => {
  return http.get("/api/rest/1/bills/settings/tenancy/"+tenancyId+"");
};

export const BillSettingsApi_updateSettingsForCompanyOwner = (http: HttpFacade, companyOwnerId: number, __input: dtos.BillSettingsDto): Promise<void> => {
  return http.put("/api/rest/1/bills/settings/companyowner/"+companyOwnerId+"", __input);
};

export const BillSettingsApi_updateSettingsForTenancy = (http: HttpFacade, tenancyId: number, __input: dtos.BillSettingsTenancyDto): Promise<void> => {
  return http.put("/api/rest/1/bills/settings/tenancy/"+tenancyId+"", __input);
};

export const BkBook2Api_book = (http: HttpFacade, preview_only: boolean, __input: dtos.BkBook2ApiInput): Promise<dtos.BkBook2Api_Result> => {
  return http.post("/api/rest/1/bk/book2?"+(preview_only==null?"":"preview_only="+preview_only+"")+"", __input);
};

export const BkBookDraftApi_deleteAllUploaded = (http: HttpFacade, draftId: number): Promise<void> => {
  return http.delete("/api/rest/1/bk/book2/draft/upload/clearall/"+draftId+"");
};

export const BkBookDraftApi_getAllForTransactions = (http: HttpFacade, draftId: number): Promise<dtos.BkBookDraftApiAllForTransactionsResult> => {
  return http.get("/api/rest/1/bk/book2/draft/upload/transaction/all/"+draftId+"");
};

export const BkBookDraftApi_getDraft = (http: HttpFacade, context: string): Promise<dtos.BkBookDraftApiEntry> => {
  return http.get("/api/rest/1/bk/book2/draft/"+context+"");
};

export const BkBookDraftApi_getGeneralUploaded = (http: HttpFacade, draftId: number): Promise<Array<dtos.BkBookDraftApiUploadedFile>> => {
  return http.get("/api/rest/1/bk/book2/draft/upload/general/"+draftId+"");
};

export const BkBookDraftApi_incrementTransactionIdsByOne = (http: HttpFacade, draftId: number, indicesLargerThan: number): Promise<void> => {
  return http.put("/api/rest/1/bk/book2/draft/upload/transaction/increment_indices_by_one/"+draftId+"/"+indicesLargerThan+"/", {});
};

export const BkBookDraftApi_removeGeneralFile = (http: HttpFacade, draftId: number, uuid: string): Promise<void> => {
  return http.delete("/api/rest/1/bk/book2/draft/upload/general/"+draftId+"/"+uuid+"");
};

export const BkBookDraftApi_removeTransactionFile = (http: HttpFacade, draftId: number, index: number, uuid: string): Promise<void> => {
  return http.delete("/api/rest/1/bk/book2/draft/upload/transaction/"+draftId+"/"+index+"/"+uuid+"");
};

export const BkBookDraftApi_saveDraft = (http: HttpFacade, context: string, __input: dtos.BkBookDraftApiEntryContent): Promise<void> => {
  return http.put("/api/rest/1/bk/book2/draft/"+context+"", __input);
};

export const BkBookDraftApi_subtractTransactionIdsByOne = (http: HttpFacade, draftId: number, indicesLargerThan: number): Promise<void> => {
  return http.put("/api/rest/1/bk/book2/draft/upload/transaction/subtract_indices_by_one/"+draftId+"/"+indicesLargerThan+"/", {});
};

export const BkBookDraftApi_uploadForTransaction = (http: HttpFacade, draftId: number, index: number, uploadMaxSize: number, file1: File): Promise<string> => {
  return http.post("/api/rest/1/bk/book2/draft/upload/transaction/"+draftId+"/"+index+"?"+(uploadMaxSize==null?"":"uploadMaxSize="+uploadMaxSize+"")+"&"+(file1==null?"":"file1="+file1+"")+"", {});
};

export const BkBookDraftApi_uploadGeneral = (http: HttpFacade, draftId: number, uploadMaxSize: number, file1: File): Promise<string> => {
  return http.post("/api/rest/1/bk/book2/draft/upload/general/"+draftId+"?"+(uploadMaxSize==null?"":"uploadMaxSize="+uploadMaxSize+"")+"&"+(file1==null?"":"file1="+file1+"")+"", {});
};

export const BkBookTenantSearch_lookup = (http: HttpFacade, tenantId: number): Promise<dtos.BkBookTenantSearchResult> => {
  return http.get("/api/rest/1/bk/book2/tenantsearch/lookup/"+tenantId+"");
};

export const BkBookTenantSearch_search = (http: HttpFacade, estateId: number, query: string): Promise<Array<dtos.BkBookTenantSearchResult>> => {
  return http.get("/api/rest/1/bk/book2/tenantsearch/"+estateId+"?"+(query==null?"":"query="+query+"")+"");
};

export const BkJournalApi2_getIdleTenancyJournal = (http: HttpFacade, tenancyId: number, __input: dtos.BkJournalApi2_Query): Promise<dtos.PaginatedJournalResult> => {
  return http.post("/api/rest/1/bk/journal2/idle_tenancy_journal/"+tenancyId+"/query", __input);
};

export const BkJournalApi2_getItems = (http: HttpFacade, allEstatesInOwner: boolean, specificGroupWithIntermediateResults: number | null, __input: dtos.BkJournalApi2_Query): Promise<dtos.PaginatedJournalResult> => {
  return http.post("/api/rest/1/bk/journal2/journal/query?"+(allEstatesInOwner==null?"":"allEstatesInOwner="+allEstatesInOwner+"")+"&"+(specificGroupWithIntermediateResults==null?"":"specificGroupWithIntermediateResults="+specificGroupWithIntermediateResults+"")+"", __input);
};

export const BkJournalApi2_getItemsAsExcel = (http: HttpFacade, __input: dtos.BkJournalApi2_Query): Promise<Uint32Array> => {
  return http.post("/api/rest/1/bk/journal2/journal/query-as-excel", __input);
};

export const BkJournalApi2_getTenancyJournal = (http: HttpFacade, tenancyId: number, __input: dtos.BkJournalApi2_Query): Promise<dtos.PaginatedJournalResult> => {
  return http.post("/api/rest/1/bk/journal2/tenancy_journal/"+tenancyId+"/query", __input);
};

export const BkJournalApi2_getTenantJournal = (http: HttpFacade, tenantId: number, __input: dtos.BkJournalApi2_Query): Promise<dtos.PaginatedJournalResult> => {
  return http.post("/api/rest/1/bk/journal2/tenant_journal/"+tenantId+"/query", __input);
};

export const BkLedgerApi_queryCompanyowner = (http: HttpFacade, companyOwnerId: number, allCompaniesInOwner: boolean, __input: dtos.BkLedgerApi_QueryParams): Promise<Array<dtos.BkLedgerApi_Entry>> => {
  return http.post("/api/rest/1/bk/ledger/query_companyowner/"+companyOwnerId+"?"+(allCompaniesInOwner==null?"":"allCompaniesInOwner="+allCompaniesInOwner+"")+"", __input);
};

export const BkLedgerApi_queryCompanyownerAndDownload = (http: HttpFacade, companyOwnerId: number, allCompaniesInOwner: boolean, format: dtos.BkLedgerApi_DownloadFormat, __input: dtos.BkLedgerApi_QueryParams): Promise<Uint32Array> => {
  return http.post("/api/rest/1/bk/ledger/query_companyowner/"+companyOwnerId+"/download?"+(allCompaniesInOwner==null?"":"allCompaniesInOwner="+allCompaniesInOwner+"")+"&"+(format==null?"":"format="+format+"")+"", __input);
};

export const BkLedgerApi_queryEstate = (http: HttpFacade, estateId: number, __input: dtos.BkLedgerApi_QueryParams): Promise<Array<dtos.BkLedgerApi_Entry>> => {
  return http.post("/api/rest/1/bk/ledger/query_estate/"+estateId+"/download", __input);
};

export const BkLedgerApi_queryEstateAndDownload = (http: HttpFacade, estateId: number, format: dtos.BkLedgerApi_DownloadFormat, __input: dtos.BkLedgerApi_QueryParams): Promise<Uint32Array> => {
  return http.post("/api/rest/1/bk/ledger/query_estate/"+estateId+"?"+(format==null?"":"format="+format+"")+"", __input);
};

export const BkLedgerApi_queryIdleTenancy = (http: HttpFacade, tenancyId: number, __input: dtos.BkLedgerApi_QueryParams): Promise<Array<dtos.BkLedgerApi_Entry>> => {
  return http.post("/api/rest/1/bk/ledger/query_idle_tenancy/"+tenancyId+"", __input);
};

export const BkLedgerApi_queryIdleTenancyAndDownload = (http: HttpFacade, tenancyId: number, format: dtos.BkLedgerApi_DownloadFormat, __input: dtos.BkLedgerApi_QueryParams): Promise<Uint32Array> => {
  return http.post("/api/rest/1/bk/ledger/query_idle_tenancy/"+tenancyId+"/download?"+(format==null?"":"format="+format+"")+"", __input);
};

export const BkLedgerApi_queryTenancy = (http: HttpFacade, tenancyId: number, __input: dtos.BkLedgerApi_QueryParams): Promise<Array<dtos.BkLedgerApi_Entry>> => {
  return http.post("/api/rest/1/bk/ledger/query_tenancy/"+tenancyId+"", __input);
};

export const BkLedgerApi_queryTenancyAndDownload = (http: HttpFacade, tenancyId: number, format: dtos.BkLedgerApi_DownloadFormat, __input: dtos.BkLedgerApi_QueryParams): Promise<Uint32Array> => {
  return http.post("/api/rest/1/bk/ledger/query_tenancy/"+tenancyId+"/download?"+(format==null?"":"format="+format+"")+"", __input);
};

export const BkLedgerApi_queryTenant = (http: HttpFacade, tenantId: number, __input: dtos.BkLedgerApi_QueryParams): Promise<Array<dtos.BkLedgerApi_Entry>> => {
  return http.post("/api/rest/1/bk/ledger/query_tenant/"+tenantId+"", __input);
};

export const BkLedgerApi_queryTenantAndDownload = (http: HttpFacade, tenantId: number, format: dtos.BkLedgerApi_DownloadFormat, __input: dtos.BkLedgerApi_QueryParams): Promise<Uint32Array> => {
  return http.post("/api/rest/1/bk/ledger/query_tenant/"+tenantId+"/download?"+(format==null?"":"format="+format+"")+"", __input);
};

export const BkVatcodesApi_getAll = (http: HttpFacade): Promise<Array<dtos.BkVatcodesApi_Entry>> => {
  return http.get("/api/rest/1/bk/vatcodes/all");
};

export const BkVatcodesApi_getAllForCompanyowner = (http: HttpFacade, owner: number): Promise<Array<dtos.BkVatcodesApi_Entry>> => {
  return http.get("/api/rest/1/bk/vatcodes/all_for_companyowner/"+owner+"");
};

export const CompanyOwnerBankinfoApi_get = (http: HttpFacade, companyOwnerId: number): Promise<dtos.CompanyOwnerBankinfoDto> => {
  return http.get("/api/rest/1/companyowner/"+companyOwnerId+"/bankinfo");
};

export const CompanyOwnerBankinfoApi_save = (http: HttpFacade, companyOwnerId: number, __input: dtos.CompanyOwnerBankinfoDto): Promise<void> => {
  return http.put("/api/rest/1/companyowner/"+companyOwnerId+"/bankinfo", __input);
};

export const CompanyOwnerBkChargetypes_create = (http: HttpFacade, companyOwnerId: number, __input: dtos.UpdateChargeTypeDto): Promise<void> => {
  return http.post("/api/rest/1/companyowner/"+companyOwnerId+"/bk/chargetypes", __input);
};

export const CompanyOwnerBkChargetypes_delete = (http: HttpFacade, companyOwnerId: number, id: number): Promise<void> => {
  return http.delete("/api/rest/1/companyowner/"+companyOwnerId+"/bk/chargetypes/"+id+"");
};

export const CompanyOwnerBkChargetypes_get = (http: HttpFacade, companyOwnerId: number): Promise<Array<dtos.ChargeTypeDto>> => {
  return http.get("/api/rest/1/companyowner/"+companyOwnerId+"/bk/chargetypes");
};

export const CompanyOwnerBkChargetypes_getScoped = (http: HttpFacade, companyOwnerId: number, scope: dtos.ChargeTypeScope): Promise<Array<dtos.ChargeTypeDto>> => {
  return http.get("/api/rest/1/companyowner/"+companyOwnerId+"/bk/chargetypes/"+scope+"");
};

export const CompanyOwnerBkChargetypes_update = (http: HttpFacade, companyOwnerId: number, id: number, __input: dtos.UpdateChargeTypeDto): Promise<void> => {
  return http.put("/api/rest/1/companyowner/"+companyOwnerId+"/bk/chargetypes/"+id+"", __input);
};

export const CompanyOwnerBkPlanApiV1_addAcc = (http: HttpFacade, companyOwnerId: number, __input: dtos.BkNewAccountDto): Promise<void> => {
  return http.post("/api/rest/1/companyowner/bkplan/"+companyOwnerId+"/account", __input);
};

export const CompanyOwnerBkPlanApiV1_deleteAccount = (http: HttpFacade, companyOwnerId: number, accountId: number): Promise<void> => {
  return http.delete("/api/rest/1/companyowner/bkplan/"+companyOwnerId+"/account/"+accountId+"");
};

export const CompanyOwnerBkPlanApiV1_getAccount = (http: HttpFacade, companyOwnerId: number, accountId: number): Promise<dtos.BkAccountDto> => {
  return http.get("/api/rest/1/companyowner/bkplan/"+companyOwnerId+"/account/"+accountId+"");
};

export const CompanyOwnerBkPlanApiV1_getAllSorted = (http: HttpFacade, companyOwnerId: number, bookableOnly: boolean): Promise<Array<dtos.BkAccountDto>> => {
  return http.get("/api/rest/1/companyowner/bkplan/"+companyOwnerId+"?"+(bookableOnly==null?"":"bookableOnly="+bookableOnly+"")+"");
};

export const CompanyOwnerBkPlanApiV1_isDeleteableAccount = (http: HttpFacade, companyOwnerId: number, accountId: number): Promise<boolean> => {
  return http.get("/api/rest/1/companyowner/bkplan/"+companyOwnerId+"/account/is_deleteable/"+accountId+"");
};

export const CompanyOwnerBkPlanApiV1_updateAcc = (http: HttpFacade, companyOwnerId: number, __input: dtos.BkUpdateAccountDto): Promise<void> => {
  return http.put("/api/rest/1/companyowner/bkplan/"+companyOwnerId+"/account", __input);
};

export const CompanyOwnerBkSettings_getSettings = (http: HttpFacade, companyOwnerId: number): Promise<dtos.CompanyOwnerBkSettingsDto> => {
  return http.get("/api/rest/1/companyowner/"+companyOwnerId+"/bk/settings");
};

export const CompanyOwnerBkSettings_updateSettings = (http: HttpFacade, companyOwnerId: number, __input: dtos.CompanyOwnerBkSettingsUpdate): Promise<void> => {
  return http.put("/api/rest/1/companyowner/"+companyOwnerId+"/bk/settings", __input);
};

export const CompanyOwnerBkYears_create = (http: HttpFacade, companyOwnerId: number, __input: dtos.BkYearDto): Promise<void> => {
  return http.post("/api/rest/1/companyowner/bkyears/"+companyOwnerId+"/bk/years", __input);
};

export const CompanyOwnerBkYears_delete = (http: HttpFacade, companyOwnerId: number, yearId: number): Promise<void> => {
  return http.delete("/api/rest/1/companyowner/bkyears/"+companyOwnerId+"/bk/years/"+yearId+"");
};

export const CompanyOwnerBkYears_get = (http: HttpFacade, companyOwnerId: number): Promise<Array<dtos.BkYearDto>> => {
  return http.get("/api/rest/1/companyowner/bkyears/"+companyOwnerId+"/bk/years");
};

export const CompanyOwnerBkYears_getByEstateId = (http: HttpFacade, estateId: number): Promise<Array<dtos.BkYearDto>> => {
  return http.get("/api/rest/1/companyowner/bkyears/by_estate_id/"+estateId+"");
};

export const CompanyOwnerBkYears_update = (http: HttpFacade, companyOwnerId: number, __input: dtos.BkYearDto): Promise<void> => {
  return http.put("/api/rest/1/companyowner/bkyears/"+companyOwnerId+"/bk/years", __input);
};

export const CompanyOwnerCommunicationSettingApi_get = (http: HttpFacade, companyOwnerId: number): Promise<dtos.CompanyOwnerCommunicationSettings_Dto> => {
  return http.get("/api/rest/1/companyowner/"+companyOwnerId+"/communication_settings");
};

export const CompanyOwnerCommunicationSettingApi_save = (http: HttpFacade, companyOwnerId: number, __input: dtos.CompanyOwnerCommunicationSettings_Dto): Promise<void> => {
  return http.put("/api/rest/1/companyowner/"+companyOwnerId+"/communication_settings", __input);
};

export const CompanyOwnerConsents_get = (http: HttpFacade, companyOwnerId: number): Promise<Array<dtos.CompanyOwnerConsentDto>> => {
  return http.get("/api/rest/1/companyowner/"+companyOwnerId+"/consents");
};

export const CompanyOwnerConsents_updateConsents = (http: HttpFacade, companyOwnerId: number, __input: Array<dtos.CompanyOwnerConsentDto>): Promise<void> => {
  return http.put("/api/rest/1/companyowner/"+companyOwnerId+"/consents", __input);
};

export const CompanyOwner_archive = (http: HttpFacade, companyOwnerId: number): Promise<void> => {
  return http.put("/api/rest/1/companyowner/"+companyOwnerId+"/archive", {});
};

export const CompanyOwner_createDkOwner = (http: HttpFacade, __input: dtos.CompanyOwnerDkDto): Promise<dtos.CompanyOwnerDto> => {
  return http.post("/api/rest/1/companyowner/dk", __input);
};

export const CompanyOwner_get = (http: HttpFacade, companyOwnerId: number): Promise<dtos.CompanyOwnerFullDto> => {
  return http.get("/api/rest/1/companyowner/"+companyOwnerId+"");
};

export const CompanyOwner_getAll = (http: HttpFacade): Promise<dtos.GetCompanyOwnersDto> => {
  return http.get("/api/rest/1/companyowner/all");
};

export const CompanyOwner_isArchivable = (http: HttpFacade, companyOwnerId: number): Promise<dtos.IsArchivableDto> => {
  return http.get("/api/rest/1/companyowner/"+companyOwnerId+"/is_archivable");
};

export const CompanyOwner_updateDelegateSigner = (http: HttpFacade, companyOwnerId: number, __input: dtos.DanishCompanyOwnerDelegateSignerDto): Promise<void> => {
  return http.put("/api/rest/1/companyowner/"+companyOwnerId+"/delegate_signer", __input);
};

export const CompanyOwner_updateDkOwner = (http: HttpFacade, companyOwnerId: number, __input: dtos.CompanyOwnerDkDto): Promise<void> => {
  return http.put("/api/rest/1/companyowner/"+companyOwnerId+"/dk", __input);
};

export const CpAppApi_getInfo = (http: HttpFacade): Promise<dtos.CpAppInfo> => {
  return http.get("/api/rest/1/cp/app/info");
};

export const CpAppBilling_createCsv = (http: HttpFacade): Promise<string> => {
  return http.get("/api/rest/1/cp/app_billing");
};

export const CpAsynctasksApi_getTaskinfo = (http: HttpFacade, __input: dtos.CpAsynctasksQuery): Promise<Array<dtos.CpAdmAsynctaskInfo>> => {
  return http.post("/api/rest/1/cp/asynctasks", __input);
};

export const CpAuthApi_checkAuth = (http: HttpFacade): Promise<dtos.CpAuthCheckResultDto> => {
  return http.get("/api/rest/1/cp/auth");
};

export const CpBackupApi_backup = (http: HttpFacade, site): Promise<Uint32Array> => {
  return http.get("/api/rest/1/cp/"+site+"/backup");
};

export const CpBkHelperApi_commitRecomputedBalancesForBkEntries = (http: HttpFacade, site, companyOwnerId: number): Promise<void> => {
  return http.get("/api/rest/1/cp/"+site+"/companyowner/"+companyOwnerId+"/commit_computed_balances_for_bkentries");
};

export const CpBkHelperApi_getRecomputedBalancesForBkEntries = (http: HttpFacade, site, companyOwnerId: number): Promise<Array<dtos.CpBkHelperApi_NewComputedEntry>> => {
  return http.get("/api/rest/1/cp/"+site+"/companyowner/"+companyOwnerId+"/recomputed_balances_for_bkentries");
};

export const CpClusterApi_getInfo = (http: HttpFacade): Promise<dtos.CpClusterApiInfo> => {
  return http.get("/api/rest/1/cp/cluster/info");
};

export const CpCompanyownersApi_createClient = (http: HttpFacade, site, companyOwnerId: number): Promise<dtos.CpCompanyownersApiCreateClientResultEither> => {
  return http.post("/api/rest/1/cp/"+site+"/companyowners/"+companyOwnerId+"/bmpayment/integration/client", {});
};

export const CpCompanyownersApi_enableClient = (http: HttpFacade, site, companyOwnerId: number, __input: dtos.CpCompanyownersApiEnableClient): Promise<void> => {
  return http.put("/api/rest/1/cp/"+site+"/companyowners/"+companyOwnerId+"/bmpayment/integration/client", __input);
};

export const CpCompanyownersApi_getBMPaymentIntegration = (http: HttpFacade, site, companyOwnerId: number): Promise<dtos.CpCompanyownersApiBMPayIntegration> => {
  return http.get("/api/rest/1/cp/"+site+"/companyowners/"+companyOwnerId+"/bmpayment/integration");
};

export const CpCompanyownersApi_getBMPaymentSubscriptions = (http: HttpFacade, site, companyOwnerId: number, tenantIdContext: number): Promise<Array<dtos.CpCompanyownersApiBmPaySubscription>> => {
  return http.get("/api/rest/1/cp/"+site+"/companyowners/"+companyOwnerId+"/bmpayment/subcriptions?"+(tenantIdContext==null?"":"tenantIdContext="+tenantIdContext+"")+"");
};

export const CpCompanyownersApi_getList = (http: HttpFacade, site): Promise<Array<dtos.CpCompanyownersApi>> => {
  return http.get("/api/rest/1/cp/"+site+"/companyowners");
};

export const CpDkNettoprice_getEntries = (http: HttpFacade): Promise<Array<dtos.CpDkNettopriceYear>> => {
  return http.get("/api/rest/1/cp/dk_nettoprice");
};

export const CpDkNettoprice_update = (http: HttpFacade): Promise<void> => {
  return http.get("/api/rest/1/cp/dk_nettoprice/update");
};

export const CpEstatesApi_getEstates = (http: HttpFacade, site: string): Promise<Array<dtos.CpEstatesDto>> => {
  return http.get("/api/rest/1/cp/"+site+"");
};

export const CpSiteApi_createSite = (http: HttpFacade, __input: dtos.CpSiteApi_CreateSiteDto): Promise<void> => {
  return http.post("/api/rest/1/cp/site", __input);
};

export const CpSiteApi_disableSite = (http: HttpFacade, site_id: number, authstamp: string): Promise<void> => {
  return http.put("/api/rest/1/cp/site/"+site_id+"/"+authstamp+"/disable", {});
};

export const CpSiteApi_enableSite = (http: HttpFacade, site_id: number, authstamp: string): Promise<void> => {
  return http.put("/api/rest/1/cp/site/"+site_id+"/"+authstamp+"/enable", {});
};

export const CpSiteApi_getSites = (http: HttpFacade): Promise<Array<dtos.AdmSiteDto>> => {
  return http.get("/api/rest/1/cp/site");
};

export const CpSiteApi_markSiteForDeletion = (http: HttpFacade, site_id: number, authstamp: string): Promise<void> => {
  return http.put("/api/rest/1/cp/site/"+site_id+"/"+authstamp+"/mark_delete", {});
};

export const CpSiteAppBilling_getAppBilling = (http: HttpFacade, site): Promise<dtos.CpSiteAppBilling_Data> => {
  return http.get("/api/rest/1/cp/"+site+"/app_billing");
};

export const CpSiteAppBilling_saveAppBilling = (http: HttpFacade, site, __input: dtos.CpSiteAppBilling_Data): Promise<dtos.CpSiteAppBilling_Data> => {
  return http.put("/api/rest/1/cp/"+site+"/app_billing", __input);
};

export const CpSiteAuthApi_checkAuth = (http: HttpFacade, site: string): Promise<dtos.CpAuthCheckResultDto> => {
  return http.get("/api/rest/1/cp/"+site+"/auth");
};

export const CpSiteUsersApi_addUser = (http: HttpFacade, site: string, __input: dtos.CpSiteUsersApi_AddUser): Promise<void> => {
  return http.post("/api/rest/1/cp/"+site+"/user/add", __input);
};

export const CpSubscriptionOnTenantsHelper_getCurrentTenantsOnSite = (http: HttpFacade, site): Promise<Array<dtos.CpSubscriptionOnTenantsHelperEntry>> => {
  return http.get("/api/rest/1/cp/"+site+"/sub-tenants-helper");
};

export const CpSubscriptionOnTenantsHelper_save = (http: HttpFacade, site, __input: dtos.CpSubscriptionsTenantsIdToIdentifiers): Promise<void> => {
  return http.put("/api/rest/1/cp/"+site+"/sub-tenants-helper", __input);
};

export const CpTenantApi_deleteTenant = (http: HttpFacade, site, tenantId: number): Promise<void> => {
  return http.delete("/api/rest/1/cp/"+site+"/tenant/"+tenantId+"/delete");
};

export const CpTenantApi_getTenant = (http: HttpFacade, site, tenantId: number): Promise<dtos.CpTenantDto> => {
  return http.get("/api/rest/1/cp/"+site+"/tenant/"+tenantId+"");
};

export const CpTenantApi_refreshSubscriptionFromProvider = (http: HttpFacade, site, tenantId: number): Promise<dtos.CpTenantApi_RefreshSubResult> => {
  return http.get("/api/rest/1/cp/"+site+"/tenant/"+tenantId+"/refresh_subscription");
};

export const CpTenantApi_setBmpaymentSubscriptionOnTenant = (http: HttpFacade, site, tenantId: number, payerReference: string): Promise<void> => {
  return http.put("/api/rest/1/cp/"+site+"/tenant/"+tenantId+"/bmpayment/set_subscription?"+(payerReference==null?"":"payerReference="+payerReference+"")+"", {});
};

export const CpUploadTenantsApi_saveNewTenants = (http: HttpFacade, site: string, __input: dtos.CpUploadTenantsAndEstatesDto): Promise<number> => {
  return http.post("/api/rest/1/cp/site/"+site+"/upload-tenants/save-tenants", __input);
};

export const CpUploadTenantsApi_uploadPreview = (http: HttpFacade, site: string, file: File): Promise<dtos.CpUploadTenantsAndEstatesDto> => {
  return http.post("/api/rest/1/cp/site/"+site+"/upload-tenants/preview?"+(file==null?"":"file="+file+"")+"", {});
};

export const CustomerInvitationApi_createProfile = (http: HttpFacade, userId: number, invitationToken: string, __input: dtos.CustomerInvitationApi_CreateProfile): Promise<void> => {
  return http.post("/api/rest/1/customer/invitation/create_profile/"+userId+"/"+invitationToken+"", __input);
};

export const CustomerInvitationApi_getTokenInfo = (http: HttpFacade, userId: number, invitationToken: string): Promise<dtos.CustomerInvitationApi_TokenInfo> => {
  return http.get("/api/rest/1/customer/invitation/get_info/"+userId+"/"+invitationToken+"");
};

export const DkContractTemplatingApi_downloadPreviewOfContractTemplate = (http: HttpFacade, estateId: number): Promise<Uint32Array> => {
  return http.get("/api/rest/1/estate/"+estateId+"/dk/contract_templating/contracttemplate/downloadpreview");
};

export const DkContractTemplatingApi_getAvailableTexts = (http: HttpFacade, estateId: number): Promise<dtos.DkContractsP11TextsDto> => {
  return http.get("/api/rest/1/estate/"+estateId+"/dk/contract_templating/available_texts");
};

export const DkContractTemplatingApi_getContractTemplate = (http: HttpFacade, estateId: number): Promise<dtos.DkContractTemplateDto> => {
  return http.get("/api/rest/1/estate/"+estateId+"/dk/contract_templating/contracttemplate");
};

export const DkContractTemplatingApi_updateContractTemplate = (http: HttpFacade, estateId: number, __input: dtos.DkContractTemplateDto): Promise<dtos.DkContractTemplateDto> => {
  return http.post("/api/rest/1/estate/"+estateId+"/dk/contract_templating/contracttemplate", __input);
};

export const DkEstateNettoPriceIndicesApi_getFeatureSettings = (http: HttpFacade, estateId: number): Promise<dtos.DkEstateNettoPriceIndicesDto> => {
  return http.get("/api/rest/1/estate/"+estateId+"/dk/netto_price_indices");
};

export const DkEstateNettoPriceIndicesApi_updateFeature = (http: HttpFacade, estateId: number, __input: dtos.DkEstateNettoPriceIndicesDto): Promise<void> => {
  return http.put("/api/rest/1/estate/"+estateId+"/dk/netto_price_indices", __input);
};

export const DkTenancyApi_update = (http: HttpFacade, tenancyId: number, __input: dtos.DkTenancyUpdateDto): Promise<void> => {
  return http.put("/api/rest/1/tenancy/"+tenancyId+"/dk", __input);
};

export const DkTenancyContractTemplating_downloadPreviewOfContractTemplate = (http: HttpFacade, tenancyId: number): Promise<Uint32Array> => {
  return http.get("/api/rest/1/tenancy/"+tenancyId+"/dk/contract_templating/contracttemplate/downloadpreview");
};

export const DkTenancyContractTemplating_generatePreviewContractForNewTenant = (http: HttpFacade, tenancyId: number, __input: dtos.NewDanishTenantDto): Promise<Uint32Array> => {
  return http.post("/api/rest/1/tenancy/"+tenancyId+"/dk/contract_templating/contractpreview", __input);
};

export const DkTenancyContractTemplating_getContractTemplate = (http: HttpFacade, tenancyId: number): Promise<dtos.DkContractTemplateTenancyInfoDto> => {
  return http.get("/api/rest/1/tenancy/"+tenancyId+"/dk/contract_templating/contracttemplate");
};

export const DkTenancyContractTemplating_updateContractTemplate = (http: HttpFacade, tenancyId: number, __input: dtos.DkContractTemplateTenancyInfoDto): Promise<void> => {
  return http.post("/api/rest/1/tenancy/"+tenancyId+"/dk/contract_templating/contracttemplate", __input);
};

export const DkTenancyNettoPriceIndicesApi_getFeatureSettings = (http: HttpFacade, tenancyId: number): Promise<dtos.DkTenancyNettoPriceIndicesDto> => {
  return http.get("/api/rest/1/tenancy/"+tenancyId+"/dk/netto_price_indices");
};

export const DkTenancyNettoPriceIndicesApi_update = (http: HttpFacade, tenancyId: number, __input: dtos.DkTenancyNettoPriceIndicesDto): Promise<void> => {
  return http.put("/api/rest/1/tenancy/"+tenancyId+"/dk/netto_price_indices", __input);
};

export const EstateAndTenantFilesApi_createFolder = (http: HttpFacade, estateId: number, tenantId: number, __input: dtos.CreateFolderInput): Promise<string> => {
  return http.put("/api/rest/1/estate/"+estateId+"/files/folder/"+tenantId+"/create", __input);
};

export const EstateAndTenantFilesApi_deleteFile = (http: HttpFacade, estateId: number, file_id: number): Promise<string> => {
  return http.delete("/api/rest/1/estate/"+estateId+"/files/file/"+file_id+"");
};

export const EstateAndTenantFilesApi_deleteFolder = (http: HttpFacade, estateId: number, folderId: number): Promise<void> => {
  return http.delete("/api/rest/1/estate/"+estateId+"/files/folder/"+folderId+"");
};

export const EstateAndTenantFilesApi_getFilesInFolder = (http: HttpFacade, estateId: number, folderId: number): Promise<Array<dtos.FileDto>> => {
  return http.get("/api/rest/1/estate/"+estateId+"/files/folder/"+folderId+"/files");
};

export const EstateAndTenantFilesApi_getFolders = (http: HttpFacade, estateId: number, tenantId: number): Promise<Array<dtos.FileFolderDto>> => {
  return http.get("/api/rest/1/estate/"+estateId+"/files/folders/"+tenantId+"");
};

export const EstateAndTenantFilesApi_setAttr = (http: HttpFacade, estateId: number, file_id: number, __input: dtos.FileAttrsDto): Promise<void> => {
  return http.put("/api/rest/1/estate/"+estateId+"/files/attr/"+file_id+"", __input);
};

export const EstateAndTenantFilesApi_uploadFile = (http: HttpFacade, estateId: number, folderId: number, file1: File): Promise<string> => {
  return http.post("/api/rest/1/estate/"+estateId+"/files/uploadfile/"+folderId+"?"+(file1==null?"":"file1="+file1+"")+"", {});
};

export const EstateApi_archive = (http: HttpFacade, estateKey: string): Promise<void> => {
  return http.put("/api/rest/1/estate/"+estateKey+"/archive", {});
};

export const EstateApi_create = (http: HttpFacade, __input: dtos.EstateApiNewEstate): Promise<string> => {
  return http.post("/api/rest/1/estate", __input);
};

export const EstateApi_get = (http: HttpFacade, estateKey: string): Promise<dtos.EstateDto> => {
  return http.get("/api/rest/1/estate/"+estateKey+"");
};

export const EstateApi_getAllEstates = (http: HttpFacade, archived: boolean): Promise<dtos.GetEstatesDto> => {
  return http.get("/api/rest/1/estate/all?"+(archived==null?"":"archived="+archived+"")+"");
};

export const EstateApi_getCountOfViewable = (http: HttpFacade, archived: boolean): Promise<number> => {
  return http.get("/api/rest/1/estate/count_viewable?"+(archived==null?"":"archived="+archived+"")+"");
};

export const EstateApi_getViewable = (http: HttpFacade, archived: boolean): Promise<dtos.GetEstatesDto> => {
  return http.get("/api/rest/1/estate/viewable?"+(archived==null?"":"archived="+archived+"")+"");
};

export const EstateApi_isArchivable = (http: HttpFacade, estateKey: string): Promise<boolean> => {
  return http.get("/api/rest/1/estate/"+estateKey+"/is_archivable");
};

export const EstateApi_update = (http: HttpFacade, estateKey: string, __input: dtos.UpdateEstateDto): Promise<void> => {
  return http.put("/api/rest/1/estate/"+estateKey+"", __input);
};

export const EstateBkSplitvatApi_getDkSplitMomsAreaVatPercentSuggestion = (http: HttpFacade, estateId: number): Promise<dtos.DkSplitMomsAreaVatPercentSuggestion> => {
  return http.get("/api/rest/1/estate/"+estateId+"/bk/splitvat/split-areavat-percent-suggestion");
};

export const EstateBkSplitvatApi_getOptions = (http: HttpFacade, estateId: number): Promise<Array<dtos.SplitVatOption>> => {
  return http.get("/api/rest/1/estate/"+estateId+"/bk/splitvat/splitvat-options");
};

export const EstateBkSplitvatApi_getSplitMoms = (http: HttpFacade, estateId: number): Promise<dtos.DkSplitMomsDto> => {
  return http.get("/api/rest/1/estate/"+estateId+"/bk/splitvat/splitmoms");
};

export const EstateBkSplitvatApi_updateSplitMoms = (http: HttpFacade, estateId: number, __input: dtos.DkSplitMomsDto): Promise<void> => {
  return http.put("/api/rest/1/estate/"+estateId+"/bk/splitvat/splitmoms", __input);
};

export const EstateListsApi_download = (http: HttpFacade, estateId: number, format: dtos.EstateListFormat, __input: dtos.DownloadEstateDataListDto): Promise<Uint32Array> => {
  return http.post("/api/rest/1/estate/"+estateId+"/tools/list2/download?"+(format==null?"":"format="+format+"")+"", __input);
};

export const EstateListsApi_getFieldDescriptors = (http: HttpFacade, estateId: number): Promise<dtos.TenantDataFieldCategories> => {
  return http.get("/api/rest/1/estate/"+estateId+"/tools/list2/field_descriptors");
};

export const EstateListsApi_query = (http: HttpFacade, estateId: number, __input: dtos.DownloadEstateDataListDto): Promise<dtos.QueryResult> => {
  return http.post("/api/rest/1/estate/"+estateId+"/tools/list2/query", __input);
};

export const EstatePortalSettingsApi_get = (http: HttpFacade, estateId: number): Promise<dtos.EstatePortalSettingsDto> => {
  return http.get("/api/rest/1/estate/"+estateId+"/portal_settings");
};

export const EstatePortalSettingsApi_put = (http: HttpFacade, estateId: number, __input: dtos.EstatePortalSettingsDto): Promise<void> => {
  return http.put("/api/rest/1/estate/"+estateId+"/portal_settings", __input);
};

export const EstateRemindersApi_create = (http: HttpFacade, estateId: number, __input: dtos.ReminderDto): Promise<void> => {
  return http.post("/api/rest/1/estate/"+estateId+"/reminders", __input);
};

export const EstateRemindersApi_deleteEntry = (http: HttpFacade, estateId: number, id: number): Promise<void> => {
  return http.delete("/api/rest/1/estate/"+estateId+"/reminders/"+id+"");
};

export const EstateRemindersApi_get = (http: HttpFacade, estateId: number): Promise<Array<dtos.ReminderDto>> => {
  return http.get("/api/rest/1/estate/"+estateId+"/reminders");
};

export const EstateRemindersApi_getTypes = (http: HttpFacade, estateId): Promise<dtos.ReminderTypesDto> => {
  return http.get("/api/rest/1/estate/"+estateId+"/reminders/types");
};

export const EstateRemindersApi_update = (http: HttpFacade, estateId: number, __input: dtos.ReminderDto): Promise<void> => {
  return http.put("/api/rest/1/estate/"+estateId+"/reminders", __input);
};

export const EstateSignandpaySettingsApi_get = (http: HttpFacade, estateId: number): Promise<dtos.EstateSignandpaySettingsDto> => {
  return http.get("/api/rest/1/estate/"+estateId+"/signandpay_settings");
};

export const EstateSignandpaySettingsApi_put = (http: HttpFacade, estateId: number, __input: dtos.EstateSignandpaySettingsDto): Promise<void> => {
  return http.put("/api/rest/1/estate/"+estateId+"/signandpay_settings", __input);
};

export const EstateTenantMessages_getSendToAll = (http: HttpFacade, estateId: number, page: number): Promise<dtos.TenantMsgsToAll> => {
  return http.get("/api/rest/1/estate/"+estateId+"/messages_to_all?"+(page==null?"":"page="+page+"")+"");
};

export const EstateTenantMessages_getSendToAllReceiverReport = (http: HttpFacade, estateId: number, include_future_tenants: boolean): Promise<dtos.SendToAllReport> => {
  return http.get("/api/rest/1/estate/"+estateId+"/messages_to_all/report?"+(include_future_tenants==null?"":"include_future_tenants="+include_future_tenants+"")+"");
};

export const EstateTenantMessages_writeMessage = (http: HttpFacade, estateId: number, subject: string, rawhtml: string, include_future_tenants: boolean, files: Array<any> | null): Promise<number> => {
  return http.post("/api/rest/1/estate/"+estateId+"/messages_to_all?"+(subject==null?"":"subject="+subject+"")+"&"+(rawhtml==null?"":"rawhtml="+rawhtml+"")+"&"+(include_future_tenants==null?"":"include_future_tenants="+include_future_tenants+"")+"&"+(files==null?"":"files="+files+"")+"", {});
};

export const EstateUtilitiesAccountingApi_getStatement = (http: HttpFacade, estateId: number, accountingId: number, yearId: number): Promise<dtos.EstateUtilStatementResultDto> => {
  return http.get("/api/rest/1/estate/"+estateId+"/utilities_accounting/statements/"+accountingId+"/"+yearId+"");
};

export const EstateUtilitiesAccountingApi_saveNewStatement = (http: HttpFacade, estateId: number, accountingId: number, yearId: number, __input: dtos.NewEstateUtilStatementDto): Promise<dtos.SaveEstateUtilStatementResultDto> => {
  return http.post("/api/rest/1/estate/"+estateId+"/utilities_accounting/statements/"+accountingId+"/"+yearId+"", __input);
};

export const EstateUtilitiesSettingsApi_getEntries = (http: HttpFacade, estateId: number): Promise<Array<dtos.EstUtilityEntryDto>> => {
  return http.get("/api/rest/1/estate/"+estateId+"/utilities_spending/settings");
};

export const EstateUtilitiesSettingsApi_getTypes = (http: HttpFacade, estateId: number): Promise<Array<dtos.EstUtilTypeDto>> => {
  return http.get("/api/rest/1/estate/"+estateId+"/utilities_spending/settings/types");
};

export const EstateUtilitiesSettingsApi_save = (http: HttpFacade, estateId: number, __input: dtos.EstUtilityEntryDto): Promise<void> => {
  return http.post("/api/rest/1/estate/"+estateId+"/utilities_spending/settings", __input);
};

export const EstateUtilitiesSettingsApi_update = (http: HttpFacade, estateId: number, __input: dtos.EstUtilityEntryDto): Promise<void> => {
  return http.put("/api/rest/1/estate/"+estateId+"/utilities_spending/settings", __input);
};

export const Files_downloadFile = (http: HttpFacade, secureuid: string): Promise<Uint32Array> => {
  return http.get("/api/rest/1/files/download/"+secureuid+"");
};

export const InvoicePdfApi_getInvoicePdf = (http: HttpFacade, invoiceUid: string): Promise<Uint32Array> => {
  return http.get("/api/rest/1/invoice/download/"+invoiceUid+"");
};

export const LetterGenerationApi_generatePreviewForClaim = (http: HttpFacade, letter_template_id: number, tenant_id: number, dkNonCompulsoryAmount: string): Promise<Uint32Array> => {
  return http.get("/api/rest/1/site/tools/letter/generate/claim/generate_preview/"+letter_template_id+"/"+tenant_id+"?"+(dkNonCompulsoryAmount==null?"":"dkNonCompulsoryAmount="+dkNonCompulsoryAmount+"")+"");
};

export const LetterGenerationApi_generatePreviewForDkTermination = (http: HttpFacade, letter_template_id: number, tenant_id: number, claim_sent_day: string, disposition_datetime: string, janitor: string): Promise<Uint32Array> => {
  return http.get("/api/rest/1/site/tools/letter/generate/dk_terminate/generate_preview/"+letter_template_id+"/"+tenant_id+"?"+(claim_sent_day==null?"":"claim_sent_day="+claim_sent_day+"")+"&"+(disposition_datetime==null?"":"disposition_datetime="+disposition_datetime+"")+"&"+(janitor==null?"":"janitor="+janitor+"")+"");
};

export const LetterGenerationApi_saveNewClaimLetter = (http: HttpFacade, __input: dtos.LetterGenerationApi_ClaimsNew): Promise<dtos.LetterGenerationApi_ClaimSaveResult> => {
  return http.post("/api/rest/1/site/tools/letter/generate/claim/new", __input);
};

export const LetterGenerationApi_saveNewDkTerminationLetter = (http: HttpFacade, __input: dtos.LetterGenerationApi_DkTerminationNew): Promise<dtos.LetterGenerationApi_ClaimSaveResult> => {
  return http.post("/api/rest/1/site/tools/letter/generate/dk_termination/new", __input);
};

export const LetterSettingsApi_getSettings = (http: HttpFacade): Promise<dtos.LetterSettingsApiDto> => {
  return http.get("/api/rest/1/site/tools/letter_settings");
};

export const LetterSettingsApi_saveClaimSettings = (http: HttpFacade, __input: dtos.LetterSettingsApi_ClaimUpdate): Promise<void> => {
  return http.put("/api/rest/1/site/tools/letter_settings/claim_letter", __input);
};

export const LetterSettingsApi_saveDkTerminateSettings = (http: HttpFacade, __input: dtos.LetterSettingsApi_DkTerminate): Promise<void> => {
  return http.put("/api/rest/1/site/tools/letter_settings/dk_term_letter", __input);
};

export const LetterTemplatesApi_analyzeFile = (http: HttpFacade, tempfileGroup: string, tempfileIdent: string): Promise<dtos.LettersApiDocxAnalyseResult> => {
  return http.get("/api/rest/1/site/tools/letter_templates/"+tempfileGroup+"/"+tempfileIdent+"");
};

export const LetterTemplatesApi_deleteLetterTemplate = (http: HttpFacade, id: number): Promise<void> => {
  return http.delete("/api/rest/1/site/tools/letter_templates/"+id+"");
};

export const LetterTemplatesApi_getAll = (http: HttpFacade): Promise<Array<dtos.LetterTemplatesLetter>> => {
  return http.get("/api/rest/1/site/tools/letter_templates");
};

export const LetterTemplatesApi_getAvailableLetterTemplates = (http: HttpFacade, purpose: dtos.LetterPurpose): Promise<dtos.LetterTemplatesApiSupportedLetterTemplatesDto> => {
  return http.get("/api/rest/1/site/tools/letter_templates/available_letter_templates?"+(purpose==null?"":"purpose="+purpose+"")+"");
};

export const LetterTemplatesApi_getVars = (http: HttpFacade, id: number): Promise<dtos.LetterTemplatesVars> => {
  return http.get("/api/rest/1/site/tools/letter_templates/"+id+"/vars");
};

export const LetterTemplatesApi_saveNewLetter = (http: HttpFacade, __input: dtos.LetterTemplatesApiNew): Promise<void> => {
  return http.post("/api/rest/1/site/tools/letter_templates", __input);
};

export const LettersApi_downloadAll = (http: HttpFacade, letterId: number): Promise<Uint32Array> => {
  return http.get("/api/rest/1/site/tools/letters/"+letterId+"/download_all");
};

export const LettersApi_getEmailRecipientInfo = (http: HttpFacade, letterId: number): Promise<Array<dtos.LettersApiRecipient>> => {
  return http.get("/api/rest/1/site/tools/letters/"+letterId+"/recipients");
};

export const LettersApi_getEmailRecipientsWithoutEmail = (http: HttpFacade, letterId: number): Promise<Array<dtos.LettersApiRecipientWithoutEmail>> => {
  return http.get("/api/rest/1/site/tools/letters/"+letterId+"/recipients_without_email");
};

export const LettersApi_getLatestClaimOfTenants = (http: HttpFacade, __input: Array<number>): Promise<dtos.LettersApi_GetLatestClaimOfTenants> => {
  return http.post("/api/rest/1/site/tools/letters/latest_claim_of_tenants", __input);
};

export const LettersApi_getLetters = (http: HttpFacade, purpose: dtos.LetterPurpose): Promise<Array<dtos.LettersApiLetterDto>> => {
  return http.get("/api/rest/1/site/tools/letters?"+(purpose==null?"":"purpose="+purpose+"")+"");
};

export const LettersApi_markSent = (http: HttpFacade, letterId: number): Promise<void> => {
  return http.put("/api/rest/1/site/tools/letters/"+letterId+"/mark_sent", {});
};

export const LettersApi_sendEmails = (http: HttpFacade, letterId: number): Promise<void> => {
  return http.put("/api/rest/1/site/tools/letters/"+letterId+"/send_emails", {});
};

export const NpiNoticeApi_downloadCombined = (http: HttpFacade, combined: string, indexMonth: number, indexYear: number): Promise<Uint32Array> => {
  return http.get("/api/rest/1/site/tools/npi_notice/download_combined/"+combined+"?"+(indexMonth==null?"":"indexMonth="+indexMonth+"")+"&"+(indexYear==null?"":"indexYear="+indexYear+"")+"");
};

export const NpiNoticeApi_getLatestValidIndexMonths = (http: HttpFacade): Promise<Array<dtos.ValidIndexYearMonth>> => {
  return http.get("/api/rest/1/site/tools/npi_notice/latest_valid_index_months");
};

export const NpiNoticeApi_getTenantsApplicableNextMonthWithoutPagination = (http: HttpFacade, indexMonth: number, indexYear: number): Promise<dtos.NpiNoticeApiApplicableList> => {
  return http.get("/api/rest/1/site/tools/npi_notice/get_all_tenants_applicable?"+(indexMonth==null?"":"indexMonth="+indexMonth+"")+"&"+(indexYear==null?"":"indexYear="+indexYear+"")+"");
};

export const NpiNoticeApi_previewNoticeLetter = (http: HttpFacade, tenantId: number, indexMonth: number, indexYear: number): Promise<Uint32Array> => {
  return http.get("/api/rest/1/site/tools/npi_notice/preview/"+tenantId+"?"+(indexMonth==null?"":"indexMonth="+indexMonth+"")+"&"+(indexYear==null?"":"indexYear="+indexYear+"")+"");
};

export const NpiNoticeApi_sendToRecipients = (http: HttpFacade, __input: dtos.NpiNoticeApiSendLetters): Promise<void> => {
  return http.post("/api/rest/1/site/tools/npi_notice/send_to_recipients", __input);
};

export const PaymentsApi_getEarliestRecurringBillingStartDayForBusinessTenancy = (http: HttpFacade, tenancyStartDay: string): Promise<dtos.BusinessTenancyRecurringBillingStartDayResponse> => {
  return http.get("/api/rest/1/payments/earliest-recurring-billing-start-day-business-tenancy?"+(tenancyStartDay==null?"":"tenancyStartDay="+tenancyStartDay+"")+"");
};

export const PaymentsApi_getRecurringBillingStartDayForPrivateTenancy = (http: HttpFacade, tenancyStartDay: string, monthIncrement: number): Promise<dtos.PrivateTenancyRecurringBillingStartDayResponse> => {
  return http.get("/api/rest/1/payments/earliest-recurring-billing-start-day-private-tenancy?"+(tenancyStartDay==null?"":"tenancyStartDay="+tenancyStartDay+"")+"&"+(monthIncrement==null?"":"monthIncrement="+monthIncrement+"")+"");
};

export const PortalAuth_auth = (http: HttpFacade, __input: dtos.AuthTokenInput): Promise<dtos.AuthTokenResult> => {
  return http.post("/api/rest/1/portal/auth/token", __input);
};

export const PortalAuth_checkToken = (http: HttpFacade, token: string): Promise<dtos.CheckTokenResult> => {
  return http.get("/api/rest/1/portal/auth/checktoken?"+(token==null?"":"token="+token+"")+"");
};

export const PortalNoticeboardApi_archiveMessage = (http: HttpFacade, estateId: number, messageid: number): Promise<void> => {
  return http.put("/api/rest/1/estate/"+estateId+"/portalnoticeboard/"+messageid+"/archive", {});
};

export const PortalNoticeboardApi_createMessage = (http: HttpFacade, estateId: number, __input: dtos.PortalNoticeboardApiMsgCreateDto): Promise<void> => {
  return http.post("/api/rest/1/estate/"+estateId+"/portalnoticeboard", __input);
};

export const PortalNoticeboardApi_deleteMessage = (http: HttpFacade, estateId: number, messageid: number): Promise<void> => {
  return http.delete("/api/rest/1/estate/"+estateId+"/portalnoticeboard/"+messageid+"");
};

export const PortalNoticeboardApi_getMessages = (http: HttpFacade, estateId: number): Promise<Array<dtos.PortalNoticeboardApiMsgDto>> => {
  return http.get("/api/rest/1/estate/"+estateId+"/portalnoticeboard");
};

export const PortalNoticeboardApi_undoArchiveMessage = (http: HttpFacade, estateId: number, messageid: number): Promise<void> => {
  return http.delete("/api/rest/1/estate/"+estateId+"/portalnoticeboard/"+messageid+"/archive");
};

export const PortalNoticeboardApi_updateMessage = (http: HttpFacade, estateId: number, __input: dtos.PortalNoticeboardApiMsgUpdateDto): Promise<void> => {
  return http.put("/api/rest/1/estate/"+estateId+"/portalnoticeboard", __input);
};

export const PortalTenants_getTenants = (http: HttpFacade): Promise<Array<dtos.PortalTenantsDto>> => {
  return http.get("/api/rest/1/portal/tenants");
};

export const PortalUserApi_checkSignupTokenValidity = (http: HttpFacade, __input: dtos.CheckSignupTokenDto): Promise<dtos.CheckSignupTokenResultDto> => {
  return http.post("/api/rest/1/portal/user/check_signup_validity", __input);
};

export const PortalUserApi_createPassword = (http: HttpFacade, __input: dtos.PortalUserCreatePasswordDto): Promise<void> => {
  return http.post("/api/rest/1/portal/user/createpassword", __input);
};

export const PortalUserApi_forgotPassword = (http: HttpFacade, __input: dtos.PortalUserForgotPasswordDto): Promise<void> => {
  return http.post("/api/rest/1/portal/user/forgotpassword", __input);
};

export const PortalUserApi_registerAppDeviceNotificationToken = (http: HttpFacade, __input: dtos.DeviceInfoDto): Promise<void> => {
  return http.put("/api/rest/1/portal/user/device_notification_token", __input);
};

export const PortalUserApi_unregisterAppDeviceNotificationToken = (http: HttpFacade, deviceId: string): Promise<void> => {
  return http.delete("/api/rest/1/portal/user/device_notification_token/"+deviceId+"");
};

export const PublicApiV1Businesses_getAll = (http: HttpFacade, page: number, pageSize: number): Promise<dtos.PaginatedBusinesses> => {
  return http.get("/publicapi/v1/businesses?"+(page==null?"":"page="+page+"")+"&"+(pageSize==null?"":"pageSize="+pageSize+"")+"");
};

export const PublicApiV1Businesses_getById = (http: HttpFacade, id: number): Promise<dtos.BusinessById> => {
  return http.get("/publicapi/v1/businesses/"+id+"");
};

export const PublicApiV1Estates_getAll = (http: HttpFacade, page: number, pageSize: number, business_id: number | null): Promise<dtos.PaginatedEstates> => {
  return http.get("/publicapi/v1/estates?"+(page==null?"":"page="+page+"")+"&"+(pageSize==null?"":"pageSize="+pageSize+"")+"&"+(business_id==null?"":"business_id="+business_id+"")+"");
};

export const PublicApiV1Estates_getById = (http: HttpFacade, id: number): Promise<dtos.EstateDto> => {
  return http.get("/publicapi/v1/estates/"+id+"");
};

export const PublicApiV1Tenancies_getAll = (http: HttpFacade, page: number, pageSize: number, estate_id: number | null): Promise<dtos.PaginatedTenancies> => {
  return http.get("/publicapi/v1/tenancies?"+(page==null?"":"page="+page+"")+"&"+(pageSize==null?"":"pageSize="+pageSize+"")+"&"+(estate_id==null?"":"estate_id="+estate_id+"")+"");
};

export const PublicApiV1Tenancies_getById = (http: HttpFacade, id: number): Promise<dtos.Tenancy> => {
  return http.get("/publicapi/v1/tenancies/"+id+"");
};

export const PublicApiV1Tenancies_getByIdDetailed = (http: HttpFacade, id: number): Promise<dtos.TenancyDetailed> => {
  return http.get("/publicapi/v1/tenancies/"+id+"/detailed");
};

export const RentCheckApi_getRentCheckCompanyOwner = (http: HttpFacade): Promise<Array<dtos.RentCheckDto>> => {
  return http.get("/api/rest/1/bk/rent-check");
};

export const SignAndPayApi_decideConsent = (http: HttpFacade, tenancyident: string, tenantId: number, token: string, consentId: number, approove: boolean): Promise<void> => {
  return http.put("/api/rest/1/signandpay/"+tenancyident+"/"+tenantId+"/"+token+"/consent/"+consentId+"/"+approove+"", {});
};

export const SignAndPayApi_get = (http: HttpFacade, tenancyident: string, tenantId: number, token: string): Promise<dtos.SignAndPayDto> => {
  return http.get("/api/rest/1/signandpay/"+tenancyident+"/"+tenantId+"/"+token+"");
};

export const SignAndPayBMPaymentApi_getMethods = (http: HttpFacade, tenancyident: string, tenantId: number, token: string): Promise<dtos.SignAndPayBMPaymentApiClientMethodsEither> => {
  return http.get("/api/rest/1/signandpay/bmpayment/"+tenancyident+"/"+tenantId+"/"+token+"/get_methods");
};

export const SignAndPayBMPaymentApi_requestPay = (http: HttpFacade, tenancyident: string, tenantId: number, token: string): Promise<dtos.SignAndPayBMPaymentApiDKFik71SlipEither> => {
  return http.get("/api/rest/1/signandpay/bmpayment/"+tenancyident+"/"+tenantId+"/"+token+"/request_pay");
};

export const SignAndPayBMPaymentApi_submitBsSubscription = (http: HttpFacade, tenancyident: string, tenantId: number, token: string, __input: dtos.PortalApiAndSignAndPayBMPaymentApiNewSub): Promise<dtos.PortalApiAndSignAndPayBMPaymentApiSubmitBSResult> => {
  return http.post("/api/rest/1/signandpay/bmpayment/"+tenancyident+"/"+tenantId+"/"+token+"/bs-subscription", __input);
};

export const SiteApi_getSiteContext = (http: HttpFacade, subdomain: string): Promise<dtos.SiteContextDto> => {
  return http.get("/api/rest/1/site/"+subdomain+"");
};

export const SiteBkChargetypes_create = (http: HttpFacade, __input: dtos.UpdateChargeTypeDto): Promise<void> => {
  return http.post("/api/rest/1/site/bk/chargetypes", __input);
};

export const SiteBkChargetypes_deleteEntry = (http: HttpFacade, id: number): Promise<void> => {
  return http.delete("/api/rest/1/site/bk/chargetypes/"+id+"");
};

export const SiteBkChargetypes_get = (http: HttpFacade): Promise<Array<dtos.ChargeTypeDto>> => {
  return http.get("/api/rest/1/site/bk/chargetypes");
};

export const SiteBkChargetypes_update = (http: HttpFacade, id: number, __input: dtos.UpdateChargeTypeDto): Promise<void> => {
  return http.put("/api/rest/1/site/bk/chargetypes/"+id+"", __input);
};

export const SiteBkPlan_addAcc = (http: HttpFacade, templateId: number, __input: dtos.BkNewAccountDto): Promise<void> => {
  return http.post("/api/rest/1/site/bk/plan/"+templateId+"/account", __input);
};

export const SiteBkPlan_deleteAccount = (http: HttpFacade, templateId: number, accountId: number): Promise<void> => {
  return http.delete("/api/rest/1/site/bk/plan/"+templateId+"/account/"+accountId+"");
};

export const SiteBkPlan_getAllSorted = (http: HttpFacade, templateId: number, bookableOnly: boolean): Promise<Array<dtos.BkAccountDto>> => {
  return http.get("/api/rest/1/site/bk/plan/"+templateId+"?"+(bookableOnly==null?"":"bookableOnly="+bookableOnly+"")+"");
};

export const SiteBkPlan_isDeleteableAccount = (http: HttpFacade, templateId: number, accountId: number): Promise<boolean> => {
  return http.get("/api/rest/1/site/bk/plan/"+templateId+"/account/is_deleteable/"+accountId+"");
};

export const SiteBkPlan_updateAcc = (http: HttpFacade, templateId: number, __input: dtos.BkUpdateAccountDto): Promise<void> => {
  return http.put("/api/rest/1/site/bk/plan/"+templateId+"/account", __input);
};

export const SiteBkSyncApi_getErrorsBySiteUser = (http: HttpFacade): Promise<Array<dtos.BkSyncErrorMsg>> => {
  return http.get("/api/rest/1/site/getAllErrors");
};

export const SiteBkSyncApi_syncBkErrorSeen = (http: HttpFacade, __input: Array<string>): Promise<void> => {
  return http.post("/api/rest/1/site/syncBkErrorSeen", __input);
};

export const SiteDkContracts_addTextInTop = (http: HttpFacade, __input: dtos.DkContractsP11TextNewDto): Promise<void> => {
  return http.post("/api/rest/1/site/dk/contracts/texts/new", __input);
};

export const SiteDkContracts_deleteSubject = (http: HttpFacade, subject: string): Promise<void> => {
  return http.delete("/api/rest/1/site/dk/contracts/subject/"+subject+"");
};

export const SiteDkContracts_deleteText = (http: HttpFacade, textId: number): Promise<void> => {
  return http.delete("/api/rest/1/site/dk/contracts/texts/"+textId+"");
};

export const SiteDkContracts_getTexts = (http: HttpFacade): Promise<dtos.DkContractsP11TextsDto> => {
  return http.get("/api/rest/1/site/dk/contracts/texts");
};

export const SiteDkContracts_renameSubject = (http: HttpFacade, __input: dtos.DkContractsP11RenameSubjectDto): Promise<void> => {
  return http.put("/api/rest/1/site/dk/contracts/subject/rename", __input);
};

export const SiteDkContracts_saveIdOrderAndSubject = (http: HttpFacade, __input: dtos.DkContractsP11TextsOrders): Promise<void> => {
  return http.put("/api/rest/1/site/dk/contracts/texts/id_order", __input);
};

export const SiteDkContracts_setText = (http: HttpFacade, __input: dtos.DkContractsP11TextUpdateDto): Promise<void> => {
  return http.put("/api/rest/1/site/dk/contracts/texts", __input);
};

export const SiteEmailBranding_deleteCurrentToplogo = (http: HttpFacade): Promise<void> => {
  return http.delete("/api/rest/1/site/settings/branding/email/delete_current_toplogo");
};

export const SiteEmailBranding_getCurrentSettings = (http: HttpFacade): Promise<dtos.SiteEmailBranding_CurrentSettings> => {
  return http.get("/api/rest/1/site/settings/branding/current");
};

export const SiteEmailBranding_previewCurrent = (http: HttpFacade): Promise<void> => {
  return http.post("/api/rest/1/site/settings/branding/email/send_preview_of_current", {});
};

export const SiteEmailBranding_uploadAndPreviewDraftToplogo = (http: HttpFacade, file1: File): Promise<void> => {
  return http.post("/api/rest/1/site/settings/branding/upload_and_preview_draft_toplogo?"+(file1==null?"":"file1="+file1+"")+"", {});
};

export const SiteEmailBranding_uploadAndSaveToplogo = (http: HttpFacade, file1: File): Promise<void> => {
  return http.post("/api/rest/1/site/settings/branding/upload_and_save_toplogo?"+(file1==null?"":"file1="+file1+"")+"", {});
};

export const SiteEstateDataApi_getValues = (http: HttpFacade): Promise<dtos.ScandinavianPriceIndexYearMonthValues> => {
  return http.get("/api/rest/1/site/data/scandinavianpriceindex/dk/yearmonthindexvalues");
};

export const SiteGeneralSettingsApi_getSecurity2factorlogin = (http: HttpFacade): Promise<dtos.SiteSettings2FactorDto> => {
  return http.get("/api/rest/1/site/settings/security/2factorlogin");
};

export const SiteGeneralSettingsApi_setSecurity2factorlogin = (http: HttpFacade, __input: dtos.SiteSettings2FactorUpdDto): Promise<string> => {
  return http.put("/api/rest/1/site/settings/security/2factorlogin", __input);
};

export const SiteSearchApi_search = (http: HttpFacade, __input: dtos.SiteSearchInput): Promise<Array<dtos.SearchResultDto>> => {
  return http.post("/api/rest/1/site/search", __input);
};

export const SiteStatisticsApi_queryTenantOws = (http: HttpFacade, __input: dtos.SiteStatisticsTenantOwsParams): Promise<dtos.SiteStatisticsTenantOwsDto> => {
  return http.post("/api/rest/1/site/statistics", __input);
};

export const SiteTempfilesApi_getFiles = (http: HttpFacade, groupidentifier: string): Promise<Array<dtos.SiteTempfilesApiFile>> => {
  return http.get("/api/rest/1/site/tempfiles/"+groupidentifier+"");
};

export const SiteUserApi_authstamp = (http: HttpFacade, __input: dtos.AuthstampInput): Promise<dtos.AuthstampResult> => {
  return http.put("/api/rest/1/site/user/authstamp", __input);
};

export const SiteUserApi_changeMyPassword = (http: HttpFacade, __input: dtos.ChangePasswordDto): Promise<void> => {
  return http.put("/api/rest/1/site/user/me/password", __input);
};

export const SiteUserApi_createOrSetApikey = (http: HttpFacade, __input: dtos.SiteuserApiCreateApikey): Promise<void> => {
  return http.put("/api/rest/1/site/user/apikey/set", __input);
};

export const SiteUserApi_enableOrDisableApikey = (http: HttpFacade, __input: dtos.SiteuserApiApikeyEnable): Promise<void> => {
  return http.put("/api/rest/1/site/user/apikey/enable", __input);
};

export const SiteUserApi_getApikey = (http: HttpFacade, __input: dtos.SiteuserApiGetApikey): Promise<dtos.SiteuserApiApikey> => {
  return http.post("/api/rest/1/site/user/apikey/get", __input);
};

export const SiteUserApi_getMe = (http: HttpFacade): Promise<dtos.MeDto> => {
  return http.get("/api/rest/1/site/user/me");
};

export const SiteUserApi_updateMe = (http: HttpFacade, __input: dtos.UpdateMeDto): Promise<void> => {
  return http.put("/api/rest/1/site/user/me", __input);
};

export const SiteUsersApi_createUser = (http: HttpFacade, __input: dtos.SiteUsersApiNewUserDto): Promise<void> => {
  return http.post("/api/rest/1/site/users", __input);
};

export const SiteUsersApi_deleteUser = (http: HttpFacade, userId: number): Promise<void> => {
  return http.delete("/api/rest/1/site/users/"+userId+"");
};

export const SiteUsersApi_getUser = (http: HttpFacade, userId: number): Promise<dtos.SiteUserGeneralDto> => {
  return http.get("/api/rest/1/site/users/"+userId+"");
};

export const SiteUsersApi_getUserEstatePermissions = (http: HttpFacade, userId: number): Promise<Array<dtos.SiteUsersApiEstatePermission>> => {
  return http.get("/api/rest/1/site/users/"+userId+"/estate_permissions");
};

export const SiteUsersApi_getUsers = (http: HttpFacade): Promise<Array<dtos.SiteUserGeneralDto>> => {
  return http.get("/api/rest/1/site/users");
};

export const SiteUsersApi_updateUser = (http: HttpFacade, userId: number, __input: dtos.SiteUsersUpdate): Promise<void> => {
  return http.put("/api/rest/1/site/users/"+userId+"", __input);
};

export const SiteUsersApi_updateUserDetails = (http: HttpFacade, userId: number, __input: dtos.SiteUsersDetailsUpdate): Promise<void> => {
  return http.put("/api/rest/1/site/users/"+userId+"/details", __input);
};

export const SiteUsersApi_updateUserEstatePermissions = (http: HttpFacade, userId: number, __input: dtos.SiteUsersApiEstatePermissionsUpdate): Promise<void> => {
  return http.put("/api/rest/1/site/users/"+userId+"/estate_permissions", __input);
};

export const SyncBkEstateApi_callbackEconomicRequestAccess = (http: HttpFacade, estateId: number, originurl: string, token: string): Promise<void> => {
  return http.get("/api/rest/1/estate/"+estateId+"/bksync/economic_register_link/requestaccesscallback?"+(originurl==null?"":"originurl="+originurl+"")+"&"+(token==null?"":"token="+token+"")+"");
};

export const SyncBkEstateApi_disableIntegration = (http: HttpFacade, estateId: number): Promise<void> => {
  return http.delete("/api/rest/1/estate/"+estateId+"/bksync");
};

export const SyncBkEstateApi_finaliseEconomicSetup = (http: HttpFacade, estateId: number, __input: dtos.BkSyncFinaliseEconomicConnectionDto): Promise<dtos.BkSyncFinaliseEconomicConnResultDto> => {
  return http.put("/api/rest/1/estate/"+estateId+"/bksync", __input);
};

export const SyncBkEstateApi_getAccountsOfProvider = (http: HttpFacade, estateId: number): Promise<Array<dtos.ProviderAccountDto>> => {
  return http.get("/api/rest/1/estate/"+estateId+"/bksync/provider_accounts");
};

export const SyncBkEstateApi_getDepartmentDistribution = (http: HttpFacade, estateId: number): Promise<Array<dtos.DepartmentDistributionDto>> => {
  return http.get("/api/rest/1/estate/"+estateId+"/bksync/departmental-distribution");
};

export const SyncBkEstateApi_getEconomicRegisterLink = (http: HttpFacade, estateId: number, redirectUrl: string): Promise<string> => {
  return http.get("/api/rest/1/estate/"+estateId+"/bksync/economic_register_link?"+(redirectUrl==null?"":"redirectUrl="+redirectUrl+"")+"");
};

export const SyncBkEstateApi_getEnabledIntegration = (http: HttpFacade, estateId: number): Promise<dtos.EstateBkSyncInteDtoOptional> => {
  return http.get("/api/rest/1/estate/"+estateId+"/bksync");
};

export const SyncBkEstateApi_getErrors = (http: HttpFacade, estateId: number): Promise<Array<dtos.BkSyncErrorMsg>> => {
  return http.get("/api/rest/1/estate/"+estateId+"/bksync/errors");
};

export const SyncBkEstateApi_getMappings = (http: HttpFacade, estateId: number): Promise<Array<dtos.BkSyncAccMappingDto>> => {
  return http.get("/api/rest/1/estate/"+estateId+"/bksync/account_mappings");
};

export const SyncBkEstateApi_saveDepartmentalDistributionSetting = (http: HttpFacade, estateId: number, __input: dtos.DepartmentDistributionDto | null): Promise<void> => {
  return http.put("/api/rest/1/estate/"+estateId+"/bksync/departmental-distribution", __input);
};

export const SyncBkEstateApi_saveMappings = (http: HttpFacade, estateId: number, __input: dtos.BkSyncAccSaveDto): Promise<void> => {
  return http.put("/api/rest/1/estate/"+estateId+"/bksync/account_mappings", __input);
};

export const SyncBkEstateApi_start = (http: HttpFacade, estateId: number): Promise<void> => {
  return http.post("/api/rest/1/estate/"+estateId+"/bksync/start", {});
};

export const SyncBkEstateApi_stop = (http: HttpFacade, estateId: number): Promise<void> => {
  return http.post("/api/rest/1/estate/"+estateId+"/bksync/stop", {});
};

export const TenancyApiV1_archiveTenancy = (http: HttpFacade, tenancyId: number): Promise<void> => {
  return http.put("/api/rest/1/tenancy/"+tenancyId+"/archive", {});
};

export const TenancyApiV1_countTenanciesForEstate = (http: HttpFacade, estateKey: string, show_archived: boolean): Promise<number> => {
  return http.get("/api/rest/1/tenancy/for_estate/"+estateKey+"/count?"+(show_archived==null?"":"show_archived="+show_archived+"")+"");
};

export const TenancyApiV1_create = (http: HttpFacade, __input: dtos.RegisterTenancyDto): Promise<number> => {
  return http.post("/api/rest/1/tenancy", __input);
};

export const TenancyApiV1_get = (http: HttpFacade, id: number): Promise<dtos.GetTenancyDto> => {
  return http.get("/api/rest/1/tenancy/"+id+"");
};

export const TenancyApiV1_getTenancies = (http: HttpFacade): Promise<dtos.ListTenancyDto> => {
  return http.get("/api/rest/1/tenancy/list");
};

export const TenancyApiV1_getTenanciesFiltered = (http: HttpFacade, __input: Array<number>): Promise<dtos.ListTenancyDto> => {
  return http.post("/api/rest/1/tenancy/list/filtered", __input);
};

export const TenancyApiV1_getTenanciesForEstate = (http: HttpFacade, estateKey: string, show_archived: boolean): Promise<dtos.ListTenancyDto> => {
  return http.get("/api/rest/1/tenancy/for_estate/"+estateKey+"?"+(show_archived==null?"":"show_archived="+show_archived+"")+"");
};

export const TenancyApiV1_getTenanciesPaginated = (http: HttpFacade, page: number, pageSize: number): Promise<dtos.TenancyApi_PaginatedEntries> => {
  return http.get("/api/rest/1/tenancy/list/paginated?"+(page==null?"":"page="+page+"")+"&"+(pageSize==null?"":"pageSize="+pageSize+"")+"");
};

export const TenancyApiV1_getTenantsWithNames = (http: HttpFacade, tenancyId: number, history_only: boolean | null): Promise<Array<dtos.GetTenantsWithNames>> => {
  return http.get("/api/rest/1/tenancy/"+tenancyId+"/tenants_with_names?"+(history_only==null?"":"history_only="+history_only+"")+"");
};

export const TenancyApiV1_isArchivable = (http: HttpFacade, tenancyId: number): Promise<boolean> => {
  return http.get("/api/rest/1/tenancy/"+tenancyId+"/is_archivable");
};

export const TenancyRentApi_changeRent = (http: HttpFacade, tenancyId: number, __input: dtos.TenancyChangeRentDto): Promise<void> => {
  return http.put("/api/rest/1/tenancy/"+tenancyId+"/rent", __input);
};

export const TenancyRentApi_getFullRentinfo = (http: HttpFacade, tenancyId: number, day: string | null): Promise<dtos.TenancyFullRentInfoDto> => {
  return http.get("/api/rest/1/tenancy/"+tenancyId+"/rent?"+(day==null?"":"day="+day+"")+"");
};

export const TenantBillsApi_createOneoffBill = (http: HttpFacade, tenantId: number, __input: dtos.TenantNewBillDto): Promise<void> => {
  return http.post("/api/rest/1/tenant/"+tenantId+"/bills/oneoff", __input);
};

export const TenantBillsApi_getList = (http: HttpFacade, tenantId: number): Promise<Array<dtos.TenantRecurrentBillDto>> => {
  return http.get("/api/rest/1/tenant/"+tenantId+"/bills/list");
};

export const TenantBillsApi_getNextRecurrentBill = (http: HttpFacade, tenantId: number): Promise<dtos.TenantRecurrentBillDtoRes> => {
  return http.get("/api/rest/1/tenant/"+tenantId+"/bills/next_recurrent");
};

export const TenantBillsApi_getOnetimeBillLines = (http: HttpFacade, tenantId: number): Promise<dtos.TenantBillsApiOnetimeBillLines> => {
  return http.get("/api/rest/1/tenant/"+tenantId+"/bills/onetime_bill_lines");
};

export const TenantBillsApi_getPreviousRecurrentBill = (http: HttpFacade, tenantId: number): Promise<dtos.TenantRecurrentBillDtoRes> => {
  return http.get("/api/rest/1/tenant/"+tenantId+"/bills/prev_recurrent");
};

export const TenantBillsApi_saveOnetimeBillLines = (http: HttpFacade, tenantId: number, __input: dtos.TenantBillsApiOnetimeBillLines): Promise<void> => {
  return http.put("/api/rest/1/tenant/"+tenantId+"/bills/onetime_bill_lines", __input);
};

export const TenantBillsApi_setAddowed = (http: HttpFacade, tenantId: number, billId: number, addowed: boolean): Promise<void> => {
  return http.get("/api/rest/1/tenant/"+tenantId+"/bills/bill/"+billId+"/set_addowed/"+addowed+"");
};

export const TenantBillsApi_togglePaid = (http: HttpFacade, tenantId: number, billId: number, __input: dtos.TogglePaid): Promise<void> => {
  return http.post("/api/rest/1/tenant/"+tenantId+"/bills/bill/"+billId+"/toggle_paid", __input);
};

export const TenantDates_changeStartdayAndFirstPeriodBillDay = (http: HttpFacade, tenantId: number, __input: dtos.TenantDatesDto): Promise<void> => {
  return http.put("/api/rest/1/tenant/"+tenantId+"/dates", __input);
};

export const TenantDates_getDates = (http: HttpFacade, tenantId: number): Promise<dtos.TenantDatesDto> => {
  return http.get("/api/rest/1/tenant/"+tenantId+"/dates");
};

export const TenantDkApi_getRentRegulationSettings = (http: HttpFacade, tenantId: number): Promise<Array<dtos.TenantChargedAmountDto>> => {
  return http.get("/api/rest/1/tenant/"+tenantId+"/dk/rent_regulation");
};

export const TenantDkApi_updateRentRegulationSettings = (http: HttpFacade, tenantId: number, __input: dtos.TenantChargedAmountUpdateDto): Promise<void> => {
  return http.put("/api/rest/1/tenant/"+tenantId+"/dk/rent_regulation", __input);
};

export const TenantDkCreationApi_clearContractPreviewPdfOnTenancy = (http: HttpFacade, tenancyId: number): Promise<void> => {
  return http.delete("/api/rest/1/tenancy/dk/tenant_creation/"+tenancyId+"/contractpreview");
};

export const TenantDkCreationApi_create = (http: HttpFacade, tenancyId: number, movingin: boolean, validateOnly: boolean | null, __input: dtos.NewDanishTenantDto): Promise<number> => {
  return http.post("/api/rest/1/tenancy/dk/tenant_creation/"+tenancyId+"/createtenant?"+(movingin==null?"":"movingin="+movingin+"")+"&"+(validateOnly==null?"":"validateOnly="+validateOnly+"")+"", __input);
};

export const TenantDkCreationApi_createContractPreviewPdfAsTempfileOnTenancy = (http: HttpFacade, tenancyId: number, __input: dtos.NewDanishTenantDto): Promise<dtos.CreateContractPreviewPdfResult> => {
  return http.put("/api/rest/1/tenancy/dk/tenant_creation/"+tenancyId+"/contractpreview", __input);
};

export const TenantDkCreationApi_downloadContractPreviewPdfAsTempfileOnTenancy = (http: HttpFacade, tenancyId: number, __input: dtos.NewDanishTenantDto): Promise<Uint32Array> => {
  return http.post("/api/rest/1/tenancy/dk/tenant_creation/"+tenancyId+"/contractpreview/download", __input);
};

export const TenantDkCreationApi_getRequiredVariables = (http: HttpFacade, tenancyId: number): Promise<dtos.TenantDkCreationRequiredVariables> => {
  return http.get("/api/rest/1/tenancy/dk/tenant_creation/"+tenancyId+"/required_variables");
};

export const TenantEnrollmentMovein_get = (http: HttpFacade, tenantId: number): Promise<dtos.GetTenantEnrollMoveInDkDto> => {
  return http.get("/api/rest/1/tenant/"+tenantId+"/enrollment/movein");
};

export const TenantEnrollmentMovein_update = (http: HttpFacade, tenantId: number, __input: dtos.TenantEnrollMoveInDkDto): Promise<void> => {
  return http.put("/api/rest/1/tenant/"+tenantId+"/enrollment/movein", __input);
};

export const TenantEnrollment_enablePortalWhenEnrolled = (http: HttpFacade, tenantId: number, __input: dtos.EnablePortalWhenEnrolledInput): Promise<void> => {
  return http.put("/api/rest/1/tenant/"+tenantId+"/enrollment/enable_portal_when_enrolled", __input);
};

export const TenantEnrollment_manualPay = (http: HttpFacade, tenantId: number): Promise<void> => {
  return http.put("/api/rest/1/tenant/"+tenantId+"/enrollment/manualpay", {});
};

export const TenantEnrollment_manualsign = (http: HttpFacade, tenantId: number): Promise<void> => {
  return http.put("/api/rest/1/tenant/"+tenantId+"/enrollment/manualsign", {});
};

export const TenantMessages_create = (http: HttpFacade, tenantId: number, subject: string, parentMessageId: number | null, rawhtml: string, files: Array<any> | null): Promise<number> => {
  return http.post("/api/rest/1/tenant/"+tenantId+"/messages?"+(subject==null?"":"subject="+subject+"")+"&"+(parentMessageId==null?"":"parentMessageId="+parentMessageId+"")+"&"+(rawhtml==null?"":"rawhtml="+rawhtml+"")+"&"+(files==null?"":"files="+files+"")+"", {});
};

export const TenantMessages_flagMessage = (http: HttpFacade, tenantId: number, messageId: number): Promise<void> => {
  return http.put("/api/rest/1/tenant/"+tenantId+"/messages/"+messageId+"/flag", {});
};

export const TenantMessages_get = (http: HttpFacade, tenantId: number, page: number): Promise<dtos.TenantMsgs> => {
  return http.get("/api/rest/1/tenant/"+tenantId+"/messages?"+(page==null?"":"page="+page+"")+"");
};

export const TenantMessages_getReceiverReport = (http: HttpFacade, tenantId: number): Promise<dtos.SendToAllReport> => {
  return http.get("/api/rest/1/tenant/"+tenantId+"/messages/report");
};

export const TenantMessages_getReplies = (http: HttpFacade, tenantId: number, parentId: number): Promise<dtos.TenantMsgReplies> => {
  return http.get("/api/rest/1/tenant/"+tenantId+"/messages/replies?"+(parentId==null?"":"parentId="+parentId+"")+"");
};

export const TenantMessages_markMessageRead = (http: HttpFacade, tenantId: number, messageId: number): Promise<void> => {
  return http.put("/api/rest/1/tenant/"+tenantId+"/messages/"+messageId+"/read", {});
};

export const TenantMessages_unflagMessage = (http: HttpFacade, tenantId: number, messageId: number): Promise<void> => {
  return http.delete("/api/rest/1/tenant/"+tenantId+"/messages/"+messageId+"/flag");
};

export const TenantMessages_unmarkMessageRead = (http: HttpFacade, tenantId: number, messageId: number): Promise<void> => {
  return http.delete("/api/rest/1/tenant/"+tenantId+"/messages/"+messageId+"/read");
};

export const TenantOccupantApi_addOccupant = (http: HttpFacade, tenantId: number, __input: dtos.TenantOccupantApi_OccupantDto): Promise<void> => {
  return http.post("/api/rest/1/tenant/"+tenantId+"/occupants/add", __input);
};

export const TenantOccupantApi_editBusinessInfo = (http: HttpFacade, tenantId: number, __input: dtos.TenantOccupantApi_OccupantEstateDto): Promise<void> => {
  return http.put("/api/rest/1/tenant/"+tenantId+"/occupants/edit_company_info", __input);
};

export const TenantOccupantApi_editOccupant = (http: HttpFacade, tenantId: number, personId: number, __input: dtos.TenantOccupantApi_OccupantDto): Promise<void> => {
  return http.put("/api/rest/1/tenant/"+tenantId+"/occupants/edit/"+personId+"", __input);
};

export const TenantOccupantApi_sendResetPasswordEmail = (http: HttpFacade, tenantId: number, personId: number): Promise<void> => {
  return http.put("/api/rest/1/tenant/"+tenantId+"/occupants/send_reset_passsword_email/"+personId+"", {});
};

export const TenantSignAndPay_enable = (http: HttpFacade, tenantId: number, __input: dtos.EnableInput): Promise<void> => {
  return http.put("/api/rest/1/tenant/"+tenantId+"/signandpay/enable", __input);
};

export const TenantSignAndPay_getBusinessSignLink = (http: HttpFacade, tenantId: number): Promise<string> => {
  return http.get("/api/rest/1/tenant/"+tenantId+"/signandpay/companysignlink");
};

export const TenantSignAndPay_sendEmails = (http: HttpFacade, tenantId: number, __input: dtos.ResendEmailsInput): Promise<void> => {
  return http.put("/api/rest/1/tenant/"+tenantId+"/signandpay/sendemails", __input);
};

export const TenantTermination_cancelTerminateTenant = (http: HttpFacade, tenantId: number): Promise<void> => {
  return http.delete("/api/rest/1/tenant/"+tenantId+"/termination");
};

export const TenantTermination_terminateTenant = (http: HttpFacade, tenantId: number, __input: dtos.TerminateTenantDto): Promise<void> => {
  return http.put("/api/rest/1/tenant/"+tenantId+"/termination", __input);
};

export const Tenant_archivePerson = (http: HttpFacade, tenantId: number, personId: number): Promise<void> => {
  return http.delete("/api/rest/1/tenant/"+tenantId+"/person/"+personId+"");
};

export const Tenant_cancel = (http: HttpFacade, tenantId: number): Promise<void> => {
  return http.put("/api/rest/1/tenant/"+tenantId+"/cancel", {});
};

export const Tenant_enablePortal = (http: HttpFacade, tenantId: number, __input: dtos.EnablePortalInput): Promise<void> => {
  return http.put("/api/rest/1/tenant/"+tenantId+"/enable_portal", __input);
};

export const Tenant_getOwedAmount = (http: HttpFacade, tenantId: number): Promise<dtos.TenantApiOwedAmount> => {
  return http.get("/api/rest/1/tenant/"+tenantId+"/owed_amount");
};

export const Tenant_getTenantFullinfo = (http: HttpFacade, tenantId: number): Promise<dtos.TenantDto> => {
  return http.get("/api/rest/1/tenant/"+tenantId+"/fullinfo");
};

export const Tenant_getTenantNameAndAdress = (http: HttpFacade, tenantId: number): Promise<dtos.NameAndAdress> => {
  return http.get("/api/rest/1/tenant/"+tenantId+"/name_and_address");
};

export const Tenant_settle = (http: HttpFacade, tenantId: number): Promise<void> => {
  return http.put("/api/rest/1/tenant/"+tenantId+"/settle", {});
};

export const Tenant_unSettle = (http: HttpFacade, tenantId: number): Promise<void> => {
  return http.delete("/api/rest/1/tenant/"+tenantId+"/settle");
};

export const Tenant_updateNote = (http: HttpFacade, tenantId: number, __input: dtos.UpdateNoteInput): Promise<void> => {
  return http.post("/api/rest/1/tenant/"+tenantId+"/update-note", __input);
};

export const TestApi2_getDevoverridedate2 = (http: HttpFacade): Promise<number> => {
  return http.get("/publicapi/v2/admin/overridedate");
};

export const TestingHelperEmailController_handleEmailRequest = (http: HttpFacade, billId: number): Promise<string> => {
  return http.post("/api/rest/1/testing_helper/email/send_bill_email?"+(billId==null?"":"billId="+billId+"")+"", {});
};

export const TpBills_getNewestToOldest = (http: HttpFacade, tenantId: number): Promise<dtos.TpBillsDto> => {
  return http.get("/api/rest/1/portal/tenant/"+tenantId+"/bill");
};

export const TpBills_payBill = (http: HttpFacade, tenantId: number, billId: number, returnUrl: string): Promise<dtos.TpPayBillResult> => {
  return http.post("/api/rest/1/portal/tenant/"+tenantId+"/bill/"+billId+"/pay?"+(returnUrl==null?"":"returnUrl="+returnUrl+"")+"", {});
};

export const TpFiles_getEstateFiles = (http: HttpFacade, tenantId: number): Promise<dtos.TpEstateFiles> => {
  return http.get("/api/rest/1/portal/tenant/"+tenantId+"/files/estate");
};

export const TpFiles_getTenantFiles = (http: HttpFacade, tenantId: number): Promise<dtos.TpTenantFiles> => {
  return http.get("/api/rest/1/portal/tenant/"+tenantId+"/files/tenant");
};

export const TpMessages_create = (http: HttpFacade, tenantId: number, subject: string, parentMessageId: number | null, rawhtml: string, files: Array<any> | null): Promise<number> => {
  return http.post("/api/rest/1/portal/tenant/"+tenantId+"/messages?"+(subject==null?"":"subject="+subject+"")+"&"+(parentMessageId==null?"":"parentMessageId="+parentMessageId+"")+"&"+(rawhtml==null?"":"rawhtml="+rawhtml+"")+"&"+(files==null?"":"files="+files+"")+"", {});
};

export const TpMessages_get = (http: HttpFacade, tenantId: number, page: number): Promise<dtos.TenantMsgs> => {
  return http.get("/api/rest/1/portal/tenant/"+tenantId+"/messages?"+(page==null?"":"page="+page+"")+"");
};

export const TpMessages_getReplies = (http: HttpFacade, tenantId: number, parentId: number): Promise<dtos.TenantMsgReplies> => {
  return http.get("/api/rest/1/portal/tenant/"+tenantId+"/messages/replies?"+(parentId==null?"":"parentId="+parentId+"")+"");
};

export const TpMessages_markMessageRead = (http: HttpFacade, tenantId: number, messageId: number): Promise<void> => {
  return http.put("/api/rest/1/portal/tenant/"+tenantId+"/messages/"+messageId+"/read", {});
};

export const TpNoticeboard_get = (http: HttpFacade, tenantId: number, page: number): Promise<dtos.TpNoticeboardEntries> => {
  return http.get("/api/rest/1/portal/tenant/"+tenantId+"/noticeboard?"+(page==null?"":"page="+page+"")+"");
};

export const TpSettings_changeEmail = (http: HttpFacade, tenantId: number, __input: dtos.ChangeEmailInput): Promise<void> => {
  return http.put("/api/rest/1/portal/tenant/"+tenantId+"/settings/changeemail", __input);
};

export const TpSettings_changePassword = (http: HttpFacade, tenantId: number, __input: dtos.SetPasswordInput): Promise<void> => {
  return http.put("/api/rest/1/portal/tenant/"+tenantId+"/settings/changepassword", __input);
};

export const TpSubscriptions_createBsSubscription = (http: HttpFacade, tenantId: number, __input: dtos.PortalApiAndSignAndPayBMPaymentApiNewSub): Promise<dtos.PortalApiAndSignAndPayBMPaymentApiSubmitBSResult> => {
  return http.post("/api/rest/1/portal/tenant/"+tenantId+"/subscription/bs", __input);
};

export const TpSubscriptions_get = (http: HttpFacade, tenantId: number): Promise<dtos.TpGetSubscription> => {
  return http.get("/api/rest/1/portal/tenant/"+tenantId+"/subscription");
};

export const TpSummary_get = (http: HttpFacade, tenantId: number): Promise<dtos.TpSummaryDto> => {
  return http.get("/api/rest/1/portal/tenant/"+tenantId+"/summary");
};

