import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { LicenseManager } from "ag-grid-enterprise";

import Bootstrap from "./Bootstrap";

LicenseManager.setLicenseKey(process.env.AG_GRID_LICENSE_KEY);

// ONLY IN DEVELOPMENT MODE (since in dev mode lies the webpack code on that host:port)
if (process.env.NODE_ENV === "development") {
  // @ts-ignore
  __webpack_public_path__ = "http://localhost:8080/";
}

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://eef5c681e9074c83afcc496189dfa1f5@o22644.ingest.sentry.io/4504802192261120",
    integrations: [new BrowserTracing()],
    ignoreErrors: [
      // Random plugins/extensions
      "top.GLOBALS",
      // Other
      "Network Error",
      "timeout exceeded",
    ],
    tracesSampleRate: 1.0,
    environment: window.location.hostname.includes("boligmanager.com") ? "production" : "staging",
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Bootstrap />
  </React.StrictMode>,
  document.getElementById("root"),
);
