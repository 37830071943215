import {
  AppPropertiesDto,
  AppTypeDto,
  MainAppContextDto,
  PortalAppContextDto,
  SignAndPayAppContextDto,
  SiteAppContextDto,
} from "./compiled_and_copied_from_java/internal_api";

export type InferredContext = {
  type: AppTypeDto,
  context: SiteAppContextDto | PortalAppContextDto | MainAppContextDto | SignAndPayAppContextDto | null,
  properties: AppPropertiesDto,
  requesterror: string | null
};
export const parseServerData = (): InferredContext => {

  const type: AppTypeDto = JSON.parse(getHtmlMetaContent("app_type") ?? "{}");
  const properties: AppPropertiesDto = JSON.parse(getHtmlMetaContent("app_properties") ?? "{}");
  let context;
  if (type === AppTypeDto.SITEAPP ||
        type === AppTypeDto.FILESAPP ||
        type === AppTypeDto.MAINAPP ||
        type === AppTypeDto.PORTALAPP ||
        type === AppTypeDto.SIGNANDPAYAPP ||
        type === AppTypeDto.INVOICEAPP) {

    context = JSON.parse(getHtmlMetaContent("app_context") || "{}");
  } else {
    throw Error("Could not load UI context..");
  }

  let requesterror = getHtmlMetaContent("request_error");
  if (requesterror != null && requesterror.length === 0) {
    requesterror = null;
  }

  return {
    type,
    context,
    properties,
    requesterror, 
  };

};

export function getHtmlMetaContent(metaName) {
  const metas = document.getElementsByTagName("meta");

  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute("name") === metaName) {
      return metas[i].getAttribute("content");
    }
  }

  return "";
}
