import React, { useEffect } from "react";
import { registerLocale } from "react-datepicker";
import { da } from "date-fns/locale";

import { disableScrollWhenModalActive } from "./components/helpers/disable_window_scroll_when_modals_active";
import { loadI18N } from "./i18n";
import { createContexts } from "./contexts";
import ApplicationLoader from "./ApplicationLoader";

registerLocale("da-DK", da);

// Create React contexts
const sc = createContexts();

const Bootstrap = () => {

  useEffect(() => {
    disableScrollWhenModalActive();
    loadI18N(() => {
    });
  }, []);

  return <ApplicationLoader context={sc} />;
};

export default Bootstrap;
