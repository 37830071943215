export const disableScrollWhenModalActive = () => {
  let windowScrollY = null;

  function scrollEventListener() {
    if (windowScrollY != null) {
      window.scrollTo(0, windowScrollY);
    }
  }

  window.addEventListener("scroll", scrollEventListener);
  setInterval(() => {
    const modals = document.getElementsByClassName("modal");
    let modalOpen = false;
    if (modals.length > 0) {
      for (let i = 0; i < modals.length; i++) {
        const modal = modals.item(i);
        // @ts-ignore
        modalOpen = modalOpen || modal.className.split(" ").find(m => m == "is-active") != null;
      }
    }
    if (modalOpen) {
      if (windowScrollY == null) {
        // @ts-ignore
        windowScrollY = window.scrollY;
      }
    } else {
      windowScrollY = null;
    }
  }, 150);

};
